// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

import app_static from '@/app_static.env'

const urlRoot = 'https://blisspot.io'

let testRoute = '/version-test';
if (app_static.env == "prod") {
  testRoute = "";
}

const baseUrl = urlRoot +testRoute+ "/api/1.1/obj";
const baseWfUrl = urlRoot +testRoute+ "/api/1.1/wf";
const webAppUrl = urlRoot+testRoute; 

var data_endpoints = {
  base_url: baseUrl,
  wf_url: baseWfUrl, 
  
  links: {
          // team // note: id = unique id in bubble database.
      team_list: baseUrl + "/team",
      team_member: baseUrl + "/team/{id}",
      buyOnWebLink: webAppUrl,
  }, 
  
  wf: {
    author_list: baseWfUrl+ "/authors-genesis",
    // course_list: baseWfUrl + '/courses-genesis',
    course_list: baseWfUrl + '/courses-search',
    nft_list: baseWfUrl + '/nfts-genesis',
    partner_list: baseWfUrl + '/partners-genesis',
    register_email: baseWfUrl+'/register-email',
    login: baseWfUrl+'/api_login',
    signup: baseWfUrl+'/api_signup',
    user: baseWfUrl + '/api_user',
    user_plan: baseWfUrl + '/api_user_plan',
    user_promo: baseWfUrl + '/api_userpromo',
    change_names: baseWfUrl+'/api_change_names',
    delete_user: baseWfUrl+'/api_delete_user',
    reset_password: baseWfUrl+'/api_send_resetpw',
    course_data: baseWfUrl+'/api_course_data',
    keyword_list: baseWfUrl+'/api-keywords',
    getsignedwasabiurlCDN: baseWfUrl+'/getsignedwasabiurlCDN',
  }

};

export default data_endpoints; 
