<template>
    <layout>
        <breadcrumb title="Course" current="Course"/>

        <!-- Start Banner Area -->
        <div class="slider-one mt--30">
            <div class="container" >
                <h2 class="title" data-sal-delay="200" data-sal="" data-sal-duration="800">
                  <span v-if="course && course.title_text"> {{ course.title_text }} </span>
                </h2>
            </div>
        </div>
        <!-- End Banner Area -->

        <div class="container">
          <div class="row g-5">
            <div class="col-12 col-md-4 col-lg-6 ">
              <img class="img_rounded_raised" :src="course && course.banner_tile_image ? course.banner_tile_image: ''" >
              <!-- alt="course" @load="$emit('handleImageLoad')" -->
            </div>

              <div class="col-12 col-md-8 col-lg-6">
                <playlist-card
                 :contents="contentsById"
                 :playlists="playlistsById"
                  :course="course"
                  />
              </div>
          </div>
      </div>

      <!-- <player :image="course && course.banner_tile_image ? course.banner_tile_image: ''" name="test"/> -->
      <!-- <player name="player"/> -->
    </layout>
</template>



<script>
import data_api from "@/libs/data_api";
import store from '@/store/index'
import app_static from '@/app_static.env'
import utils from "@/libs/utils"
import Layout from '@/components/layouts/Layout'
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";

import SalScrollAnimationMixin from '@/mixins/SalScrollAnimationMixin'

import Course from '@/components/app/Course'
import Player from '@/components/app/Player'
import PlaylistCard from '@/components/app/PlaylistCard'
import { mixedTypeAnnotation } from '@babel/types'


export default {
    name: 'CoursePlayer',
    components: {
      Layout,
      Course,
      Player,
      PlaylistCard,
      Breadcrumb
    },
    methods: {
      togglePlayer() {
        utils.console.log("toggle player: ", store.state.player.showPlayer);
        store.set("player/showPlayer", ! store.state.player.showPlayer);
      },

      init_course_data(courseId) {
        data_api.course.data({'course': courseId})
          .then((resp) => {
            utils.console.log("course player course data resp: ", resp);
            // store.set("playlist/list", resp.response.playlists);
            // store.set("content/list", resp.response.contents);
            this.contents.push(resp.data.response.contents);
            this.contentsById = utils.sort.listById(resp.data.response.contents, '_id');

            utils.console.log("course player course contents: ", this.contentsById);
            utils.console.log("course player resp data response playlists: ", resp.data.response.playlists);

            this.playlists.push(resp.data.response.playlists); 
            this.playlistsById = utils.sort.listById(resp.data.response.playlists, '_id');

            utils.console.log("course player course playlists arr: ", this.playlists);
            utils.console.log("course player course playlistsById: ", this.playlistsById);

            this.course = resp.data.response.course; 
            utils.console.log("course player course: ", this.course);

          })
          .catch((error) => {
            console.error("course player init course data error: ", error);
            this.$notify({
                title: "<h2>Not found!</h2>",
                text: "<h4> Course Unknown <br/>" + courseId + "</h4>", 
                type: 'warning',
                duration: 2000
            });
            this.$router.push('/');

          });
      }

    }, 

    mounted() {
      utils.console.log("course player course found, ", store.state.course.by_id[this.$route.params.id] ); 
      
      this.course = store.state.course.by_id[this.$route.params.id]

      this.init_course_data(this.$route.params.id)
    },
    computed: {
    },
    data() {
        return {
            lang:"en",
            app_static: app_static,
            
            playlists: [],
            playlistById: {},

            contents: [],
            contentsById:{},

            course: {}

            // course: {
            //   image: "https://import.cdn.thinkific.com/269860/2AT3iMoTAWCyu5f2yjWY_wellness%20yoga%20%281%29%20%281%29.jpg" 
            // }
        }
    },
    mixins: [SalScrollAnimationMixin]
}
</script>