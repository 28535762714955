export default {
  "data": {
      "status": "success",
      "response": {
          "data": [
              {
                  "Modified Date": 1678593769548,
                  "Created Date": 1665824775927,
                  "Created By": "admin_user_blisspot1_test",
                  "_address_text": "0x495f947276749ce646f68ac8c248420045cb7b5e",
                  "collection_individual_boolean": true,
                  "is_own_nft_boolean": true,
                  "is_partner_boolean": false,
                  "nft_id_text": "0x74b18358bfa21575a346335b380bc85f3b2037560000000000000500000007d0",
                  "partner_custom_membershippartners": "1665466751165x883841732734507400",
                  "name_text": "Unlimited Access - 7 days",
                  "logo_image": "//s3.amazonaws.com/appforest_uf/f1678009549002x514993630839921700/Genesis%207%20days.jpg",
                  "network_option_supportedweb3network": "ethereum",
                  "enabled_boolean": true,
                  "nft_type_option_supportednfttype": "ERC1155",
                  "promo_tag_list_text": [
                      "blisspot",
                      "unlimited",
                      "7 days",
                      "plan",
                      "genesis"
                  ],
                  "promo_priority_number": 2,
                  "promo_desc_text": "Enjoy a 7 day unlimited access to all premium content.  After, you can gift it to another person for 7 days.",
                  "promo_title_text": "7 days Unlimited ",
                  "promo_price_usd_number": 5,
                  "sale_mkt_url_text": "https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/52781928887552736478143538359707064702802598001025130620918424588002516797392",
                  "sale_marketplace_option_supportednftmarket": "opensea",
                  "roadmap_url_text": "https://blisspot.io/genesis",
                  "whitepaper_url_text": "https://blisspot.io/genesis",
                  "promo_detail_url_text": "https://blisspot.io/genesis",
                  "video_file": "//s3.amazonaws.com/appforest_uf/f1678009559426x571111225587800400/7%20day%20-%20Large%20Genisis%20Access%20Pass.mp4",
                  "long_desc_text": "Boost your wellbeing and energy levels with 7 days of unlimited access to Blisspot online courses, podcasts and meditations. Experience the benefits of guided meditations, energizing workouts, and inspiring talks from wellness experts. Share the gift of wellness with a friend after your 7-day experience.  Don't miss out on this incredible opportunity to prioritize your wellbeing and recharge your energy levels. Click the button below to start your exclusive 7-Day [Genesis] Blisspot journey to a healthier, happier you!",
                  "tokenomics_items_list_text": [
                      "Max Supply: 2,000",
                      "Holders will automatically be whitelisted for our highly-anticipated Founding membership launch.",
                      "5% of lucky holders will be entered into a draw for a Founding membership airdrop in June 2023. Don't miss out on this exclusive opportunity!"
                  ],
                  "_id": "1665824775927x643783389149280500"
              },
              {
                  "Modified Date": 1678593787985,
                  "Created Date": 1665730959842,
                  "Created By": "admin_user_blisspot1_test",
                  "_address_text": "0x495f947276749ce646f68ac8c248420045cb7b5e",
                  "collection_individual_boolean": true,
                  "is_own_nft_boolean": true,
                  "is_partner_boolean": false,
                  "nft_id_text": "0x74b18358bfa21575a346335b380bc85f3b2037560000000000000600000003e8",
                  "partner_custom_membershippartners": "1665466751165x883841732734507400",
                  "name_text": "Unlimited Access  - 1 Month",
                  "logo_image": "//s3.amazonaws.com/appforest_uf/f1678009592256x707962283723235000/Genesis%201%20Month.jpg",
                  "network_option_supportedweb3network": "ethereum",
                  "enabled_boolean": true,
                  "nft_type_option_supportednfttype": "ERC1155",
                  "promo_tag_list_text": [
                      "blisspot",
                      "unlimited",
                      "1 month",
                      "plan",
                      "genesis"
                  ],
                  "promo_priority_number": 3,
                  "promo_desc_text": "Enjoy access to all premium content and world class authors for 1 months.",
                  "promo_title_text": "1 Month Unlimited ",
                  "promo_price_usd_number": 15,
                  "sale_mkt_url_text": "https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/52781928887552736478143538359707064702802598001025130620918424589102028424168",
                  "sale_marketplace_option_supportednftmarket": "opensea",
                  "roadmap_url_text": "https://blisspot.io/genesis",
                  "whitepaper_url_text": "https://blisspot.io/genesis",
                  "promo_detail_url_text": "https://blisspot.io/genesis",
                  "video_file": "//s3.amazonaws.com/appforest_uf/f1678009601034x575603214588767940/1%20month%20-%20Large%20Genisis%20Access%20Pass.mp4",
                  "long_desc_text": "Elevate your wellbeing with 1 month of unlimited access to Blisspot online courses, podcasts and meditations created by world-class authors and experts. Energize your mind, body, and soul with guided meditations, invigorating workouts, and inspiring talks that reduce stress and increase focus.  Don't wait to take control of your health! Click the button below and start your exclusive 1-month [Genesis] Blisspot journey to boost your wellbeing and recharge your energy levels. ",
                  "tokenomics_items_list_text": [
                      "Max Supply: 1,000",
                      "Holders will automatically be whitelisted for our highly-anticipated Founding membership launch.",
                      "10% of lucky holders will be entered into a draw for a Founding membership airdrop in June 2023. Don't miss out on this exclusive opportunity!"
                  ],
                  "_id": "1665730959842x386807128955911000"
              },
              {
                  "Modified Date": 1678593809266,
                  "Created Date": 1665730877540,
                  "Created By": "admin_user_blisspot1_test",
                  "_address_text": "0x495f947276749ce646f68ac8c248420045cb7b5e",
                  "collection_individual_boolean": true,
                  "is_own_nft_boolean": true,
                  "is_partner_boolean": false,
                  "nft_id_text": "0x74b18358bfa21575a346335b380bc85f3b2037560000000000000700000001f4",
                  "partner_custom_membershippartners": "1665466751165x883841732734507400",
                  "name_text": "Unlimited Access - 6 Months",
                  "logo_image": "//s3.amazonaws.com/appforest_uf/f1678009617924x272533718545223230/Genesis%206%20Months.jpg",
                  "network_option_supportedweb3network": "ethereum",
                  "enabled_boolean": true,
                  "nft_type_option_supportednfttype": "ERC1155",
                  "promo_tag_list_text": [
                      "unlimited",
                      "blisspot",
                      "6 months",
                      "plan",
                      "genesis"
                  ],
                  "promo_priority_number": 4,
                  "promo_desc_text": "Enjoy access to all premium content and world class authors for 6 months.",
                  "promo_title_text": "6 months Unlimited",
                  "promo_price_usd_number": 77,
                  "sale_mkt_url_text": "https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/52781928887552736478143538359707064702802598001025130620918424590201540051444",
                  "sale_marketplace_option_supportednftmarket": "opensea",
                  "roadmap_url_text": "https://blisspot.io/genesis",
                  "whitepaper_url_text": "https://blisspot.io/genesis",
                  "promo_detail_url_text": "https://blisspot.io/genesis",
                  "video_file": "//s3.amazonaws.com/appforest_uf/f1678009625796x545503950255597200/6%20months%20-%20Large%20Genisis%20Access%20Pass.mp4",
                  "long_desc_text": "Supercharge your wellbeing with 6 months of unlimited access to Blisspot's online courses, podcasts, and meditations. Our global experts will guide you every step of the way to reduce stress, increase focus, and elevate your quality of life.  Click the button below and start your exclusive 6-month [Genesis] Blisspot opportunity to thrive in today's fast-paced world. Don't miss out on this amazing opportunity to take your wellbeing to the next level.",
                  "tokenomics_items_list_text": [
                      "Max Supply: 500",
                      "Holders will automatically be whitelisted for our highly-anticipated Founding membership launch.",
                      "50% of lucky holders will be entered into a draw for a Founding membership airdrop in June 2023. Don't miss out on this exclusive opportunity!"
                  ],
                  "_id": "1665730877539x837425359982616200"
              },
              {
                  "Modified Date": 1678593827597,
                  "Created Date": 1665730691675,
                  "Created By": "admin_user_blisspot1_test",
                  "_address_text": "0x495f947276749ce646f68ac8c248420045cb7b5e",
                  "collection_individual_boolean": true,
                  "is_own_nft_boolean": true,
                  "is_partner_boolean": false,
                  "nft_id_text": "0x74b18358bfa21575a346335b380bc85f3b2037560000000000000800000000c8",
                  "partner_custom_membershippartners": "1665466751165x883841732734507400",
                  "name_text": "Unlimited Access - 12 Months",
                  "logo_image": "//s3.amazonaws.com/appforest_uf/f1678009637174x795784258008396300/Genesis%2012%20Months.jpg",
                  "network_option_supportedweb3network": "ethereum",
                  "enabled_boolean": true,
                  "nft_type_option_supportednfttype": "ERC1155",
                  "promo_tag_list_text": [
                      "blisspot",
                      "unlimited",
                      "12 months",
                      "plan",
                      "genesis"
                  ],
                  "promo_priority_number": 5,
                  "promo_desc_text": "Enjoy access to all premium content and world class authors for 12 months.",
                  "promo_title_text": "12 Months Unlimited",
                  "promo_price_usd_number": 149,
                  "sale_mkt_url_text": "https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/52781928887552736478143538359707064702802598001025130620918424591301051678920",
                  "sale_marketplace_option_supportednftmarket": "opensea",
                  "roadmap_url_text": "https://blisspot.io/genesis",
                  "whitepaper_url_text": "https://blisspot.io/genesis",
                  "promo_detail_url_text": "https://blisspot.io/genesis",
                  "video_file": "//s3.amazonaws.com/appforest_uf/f1678009644087x935857582519328300/12%20months%20-%20Large%20Genisis%20Access%20Pass.mp4",
                  "long_desc_text": "Transform your wellbeing with 12 months of unlimited access to Blisspot's online courses, podcasts, and meditations. Experience the transformative power of our cutting-edge content, designed to help you reduce stress, increase focus, and elevate your overall quality of life  Click the button below to start the exclusive 12-month [Genesis] Blisspot opportunity to prioritize your health and wellbeing. Unlock your full potential and live your best life.",
                  "tokenomics_items_list_text": [
                      "Max Supply: 200",
                      "Holders will automatically be whitelisted for our highly-anticipated Founding membership launch.",
                      "100% of lucky holders will be entered into a draw for a Founding membership airdrop in June 2023. Don't miss out on this exclusive opportunity!"
                  ],
                  "_id": "1665730691675x422997637951123800"
              }
          ]
      }
  },
  "status": 200,
  "statusText": "OK",
  "headers": {
      "content-type": "application/json"
  },
  "config": {
      "transitional": {
          "silentJSONParsing": true,
          "forcedJSONParsing": true,
          "clarifyTimeoutError": false
      },
      "transformRequest": [
          null
      ],
      "transformResponse": [
          null
      ],
      "timeout": 0,
      "xsrfCookieName": "XSRF-TOKEN",
      "xsrfHeaderName": "X-XSRF-TOKEN",
      "maxContentLength": -1,
      "maxBodyLength": -1,
      "env": {
          "FormData": null
      },
      "headers": {
          "Accept": "application/json, text/plain, */*"
      },
      "baseURL": "https://blisspot.io/version-test/api/1.1/obj",
      "withCredentials": false,
      "credentials": false,
      "method": "get",
      "url": "https://blisspot.io/version-test/api/1.1/wf/nfts-genesis"
  },
  "request": {}
}
