<template>
    <div id="courses" class="rn-new-items mt--70">
        <!-- <breadcrumb title="Course" current="Course"/> -->

        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-9 col-md-6 col-sm-6 col-12">
                    <h3 class="title mb--0">
                        {{ app_static.course.title }}
                    </h3>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12 mt_mobile--50">
                    <div class="view-more-btn text-start text-sm-end">
                        <button class="btn btn-sm btn-primary" @click="showSearch = !showSearch">
                            <span class="mr--5" v-show="!showSearch">SEARCH</span>
                            <span class="mr--5" v-show="showSearch">HIDE</span>
                            <i class="feather-search" />
                        </button>
                    </div>
                </div>
            </div>

            <div id="course_search_panel" class="row g-5 mt--20 mt_mobile--5" v-show="showSearch">
                <!-- <div id="course_search_panel" class="course_search_panel d-flex justify-content-center flex-column" v-show="showSearch"> -->
                <!-- Search Input Start -->
                <div class=" search_bar">

                    <div class="form-group search_view_search">
                        <button @click="search(searchText)" :disabled="searchText.length < app_static.minSearchTextLength"
                            class="search_input_icon_btn">
                            <i class="feather-search" />
                        </button>
                        <input id="searchInputElem" ref="searchInputElem" class="search_input" type="text"
                            placeholder="Search ..." v-model="searchText">
                    </div>

                </div>
                <!-- Search Input -->

                <!-- Keywords Cards -->
                <div class="keyword_cards mt--0">
                    <div class="row">
                        <div
                            class="button-group isotop-filter filters-button-group d-flex justify-content-start justify-content-lg-end mt_md--10 mt_sm--10">
                            <button @click="selectKeyword()" data-filter="*"
                                :class="isWordSelected() ? 'is-checked' : ''">All</button>
                            <button @click="selectKeyword(word)" :class="isWordSelected(word) ? 'is-checked' : ''"
                                v-for="(word, index) in keywords" :key="index">{{ word.label_text }}</button>
                        </div>
                    </div>
                </div>
                <!-- Keywords Input -->
            </div>

            <div class="row g-5 mt--50 mt_mobile--5">
                <div v-show="true" class="col-lg-4 col-md-6 col-sm-6 col-12" v-for="(item, index) in members()"
                    :key="'members-item-' + index" data-sal="" :data-sal-delay="150 + index" data-sal-duration="800">
                    <course-card :product-date="item" product-style-class="no-overlay" @click="goToCourse(item)" />
                </div>
            </div>

            <div class="mt--50 d-flex align-items-center justify-content-between">

                <div class="view-more-btn text-start text-sm-end">

                    <button @click="showMore()" class="btn btn-secondary">
                        MORE <i class="feather-arrow-down" />
                    </button>

                </div>


                <div class="view-more-btn text-start text-sm-end">

                    <button @click="goToSearch()" class="btn btn-secondary">
                        SEARCH <i class="feather-search" />
                    </button>
                    <!-- 
                        <router-link class="btn btn-secondary" to="/search" >
                            SEARCH <i class="feather-search"/>
                        </router-link> -->

                </div>
            </div>

        </div>
    </div>
</template>

<script>
// import ProductCard from '@/components/product/ProductCard'
import CourseCard from '@/components/app/CourseCard'
import store from '@/store/index'
import data_api from "@/libs/data_api";
// import Breadcrumb from "@/components/breadcrumb/Breadcrumb";

import { get, sync } from "vuex-pathify";
import app_static from '@/app_static.env'

import utils from "@/libs/utils"
// import { ref } from 'vue';


export default {
    name: 'Course',
    components: {
        // ProductCard,
        CourseCard,
        // Breadcrumb
    },
    computed: {
        course: sync('course/list'),
        keywords() {
            return store.state.keyword.list ? utils.filter.byFieldValue(store.state.keyword.list, 'category_text', 'pills') : [];
        },
    },
    mounted() {
    },
    methods: {

        goToSearch() {
            this.showSearch = true;
            // this.$refs.searchInput.$el.focus();
            setTimeout(function () {
                document.getElementById("searchInputElem").focus();
            }, 300);

            // const sel = document.getElementById('course_search_panel');
            // if (sel) {
            //     sel.scrollIntoView();
            // }
        },

        search(searchText) {
            utils.console.log("search by text : ", searchText);

            // add to keywords list if not already exists
            if (searchText) {
                utils.keywords.addIfNotExist(searchText, 'pills');
            }

            data_api.course.index(0, searchText)
                .then((resp) => {
                    utils.console.log("course search resp: ", resp);
                    utils.console.log("store course by id after search: ", store.state.course.by_id);
                    utils.console.log("store course keyword after search: ", store.state.course.search_text);
                })
                .catch((error) => {
                    console.error("course search faild: ", error)
                });

        },

        selectKeyword(word) {
            // handle all 
            if (!word) {
                store.set('keyword/selected', "");
                utils.console.log("keyword selected back to all");
                this.search("");
            } else {
                let text = word && word.keyword_text ? word.keyword_text : ''
                store.set('keyword/selected', text);
                utils.console.log("keyword selected : ", word)
                this.search(text);
            }

        },

        isWordSelected(word) {
            let text = word && word.keyword_text ? word.keyword_text : ''
            if (store.state.keyword.selected == text) {
                return true;
            } else {
                return false;
            }
        },
        goToCourse(item) {
            utils.console.log("go to course: ", item);
            if (item && item._id) {
                this.$router.push('/course/' + item._id)
            }

        },

        showMore() {
            utils.console.log("show more");

            data_api.course.more()
                .then((data) => {
                    utils.console.log("course more resp: ", data);
                    utils.console.log("store course by id after more: ", store.state.course.by_id);
                    utils.console.log("store course list after more: ", store.state.course.list);
                    utils.console.log("store course keyword after more: ", store.state.course.search_text);
                })
                .catch((error) => {
                    console.error("course more faild: ", error)
                });

        },

        members() {
            // return this.team;
            utils.console.log("store course list: ", store.state.course.list);
            if (store.state.course && store.state.course.list
                && store.state.course.list.length > 0) {
                // return store.state.course.list;
                return store.state.course.list;
            } else {
                return [];
            }
        }
    },
    data() {
        return {
            app_static: app_static,
            searchText: "",
            showSearch: false,
        }
    }
}
</script>