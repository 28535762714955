<template>
    <layout>
        <breadcrumb title="Account" current="Account"/>

        <div class="edit-profile-area mt--50">
            <div class="container">
                <!-- <div class="row plr--70 padding-control-edit-wrapper pl_md--0 pr_md--0 pl_sm--0 pr_sm--0">
                    <div class="col-12 d-flex justify-content-between mb--30 align-items-center">
                        <h4 class="title-left">Edit Your Profile</h4>
                        <router-link to="/author/1" class="btn btn-primary ml--10">
                            <i class="feather-eye mr--5"></i> Preview
                        </router-link>
                    </div>
                </div> -->
                <div class="row plr--70 padding-control-edit-wrapper pl_md--0 pr_md--0 pl_sm--0 pr_sm--0">
                    <div class="col-lg-3 col-md-3 col-sm-12">
                        <nav class="left-nav rbt-sticky-top-adjust-five">
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                <!-- <button class="nav-link active"
                                        id="nav-home-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-home"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-home"
                                        aria-selected="true">
                                    <i class="feather-edit"/> Edit Profile Image
                                </button> -->
                                <button class="nav-link active"
                                        id="nav-home-tabs"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-homes"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-homes"
                                        aria-selected="false">
                                    <i class="feather-user"/> Personal Information
                                </button>

                                
                                <!-- <button class="nav-link"
                                        id="nav-profile-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-profile"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-profile"
                                        aria-selected="false">
                                    <i class="feather-unlock"/> Reset Password
                                </button> -->


                                <button class="nav-link"
                                        id="nav-home-tabs"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-deleteuser"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-deleteuser"
                                        aria-selected="false">
                                    <i class="feather-user"/> Delete My Account
                                </button>

                                <!-- <button class="nav-link"
                                        id="nav-contact-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-contact"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-contact"
                                        aria-selected="false">
                                    <i class="feather-bell"/> Notification Setting
                                </button> -->
                            </div>
                        </nav>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-12 mt_sm--30">
                        <div class="tab-content tab-content-edit-wrapepr" id="nav-tabContent">
                            <!-- <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <div class="nuron-information">
                                    <div class="profile-change row g-5">
                                        <div class="profile-left col-lg-4">
                                            <div class="profile-image mb--30">
                                                <h6 class="title">Change Your Profile Picture</h6>
                                                <img id="profilePicture"
                                                     :src="require(`@/assets/images/profile/profile-01.jpg`)"
                                                     alt="Profile-NFT"
                                                     @click="$refs.profileImageInput.click()">
                                            </div>
                                            <div class="button-area">
                                                <div class="brows-file-wrapper">
                                                    <input id="fatima"
                                                           type="file"
                                                           @change="previewImage($event, 'profilePicture')"
                                                           ref="profileImageInput">
                                                    <label for="fatima" title="No File Choosen">
                                                        <span class="text-center color-white">Upload Profile</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-left right col-lg-8">
                                            <div class="profile-image mb--30">
                                                <h6 class="title">Change Your Cover Photo</h6>
                                                <img id="coverPhoto"
                                                     :src="require(`@/assets/images/profile/cover-01.jpg`)"
                                                     alt="Profile-NFT"
                                                     @click="$refs.coverPhotoInput.click()">
                                            </div>
                                            <div class="button-area">
                                                <div class="brows-file-wrapper">
                                                    <input id="nipa"
                                                           type="file"
                                                           @change="previewImage($event, 'coverPhoto')"
                                                           ref="coverPhotoInput">
                                                    <label for="nipa" title="No File Choosen">
                                                        <span class="text-center color-white">Upload Cover</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <div class="tab-pane fade show active" id="nav-homes" role="tabpanel" aria-labelledby="nav-home-tab">
                                <div class="nuron-information">
                                    <div class="profile-form-wrapper">
                                        <div class="input-two-wrapper mb--15">
                                            <div class="first-name half-wid">
                                                <label for="contact-name" class="form-label">First Name</label>
                                                <input id="contact-name" type="text" v-model="formData.first_name">
                                            </div>
                                            <div class="last-name half-wid">
                                                <label for="contact-name-last" class="form-label">Last Name</label>
                                                <input id="contact-name-last" type="text" v-model="formData.last_name">
                                            </div>
                                        </div>
                                        <div class="email-area">
                                            <label for="Email" class="form-label">Your Email (account id)
                                                <!-- (<a :href="'mailto:' +app_static.contact">contact support to change</a>) -->
                                            </label>
                                            <input disabled id="Email" type="email" placeholder="example@blisspot.com" v-model="formData.email">
                                            <!-- <label>{{ email }}</label> -->
                                        </div>
                                    </div>

                                    <div class="button-area save-btn-edit">
                                        <!-- <a href="#" class="btn btn-primary-alta mr--15" @click="alert('Cancel Edit Profile?')">Cancel</a> -->
                                        <a href="#" class="btn btn-primary" @click="savePersonalInfo()">Save</a>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                <div class="nuron-information">
                                    <div class="condition">
                                        <h5 class="title">Reset Your Password</h5>
                                        <p class="condition">
                                            {{ app_static.account.resetpw_inst }}
                                        </p>
                                        <hr />
                                        <div class="email-area">
                                            <label for="Email2" class="form-label">Your Email</label>
                                            <input disabled id="Email2" type="email" v-model="formData.email">
                                        </div>
                                    </div>

                                    <button class="btn btn-primary save-btn-edit" @click="resetPassword()">
                                        {{ app_static.account.resetpw_btn_text }}
                                    </button>
                                </div>
                            </div> -->

                            <div class="tab-pane fade " id="nav-deleteuser" role="tabpanel" aria-labelledby="nav-deleteuser">
                                <div class="nuron-information">
                                    <h5 class="title">{{app_static.account.delete_acct_title}}</h5>
                                    <p class="notice-disc">
                                        {{
                                            app_static.account.delete_acct_message
                                        }}
                                    </p>
                                    <hr/>
                                    <label class="filter-leble">{{app_static.account.reasons_title}}</label>
                                            
                                    <div class="profile-form-wrapper">
                                        <div class="input-two-wrapepr-prifile">
                                            
                                            <select class="profile-edit-select  mt--0 ml--0" 
                                                v-model="formData.reason"
                                            >
                                                <option :value="option.value"  v-for="(option, index) in deleteReasons" :key="index">
                                                    {{ option.text }}
                                                </option>
                                            </select>
                                        </div>


                                        <div class="single-notice-setting mt--30">
                                            <div class="content-text mr--5">
                                                {{app_static.account.keepupdated_title}}
                                            </div>
                                            <div class="input">
                                                <input v-model="formData.keepupdated" type="checkbox" id="acctformDatakeepupdated" name="acctformDatakeepupdated" class="theme-switch__input" />
                                                <label for="acctformDatakeepupdated" class="theme-switch__label">
                                                    <span></span>
                                                </label>
                                            </div>
                                        </div>


                                        <div class="comment-area">

                                            <div class="edit-bio-area mt--30">
                                                <label for="comment" class="form-label">
                                                    {{ app_static.account.comments_title }}
                                                </label>
                                                <textarea v-model="formData.comments" id="comment" placeholder="enter your comments here ..."></textarea>
                                            </div>
                                            <!-- <label>{{ email }}</label> -->
                                        </div>
                                    </div>

                                    <!-- <button class="btn btn-primary save-btn-edit" @click="confirmDeleteAccount()">
                                        Submit
                                    </button> -->
                                    <button type="button" 
                                        class="btn btn-primary save-btn-edit" 
                                        data-bs-toggle="modal"
                                        data-bs-target="#confirmDeleteAccountModal">
                                        {{ app_static.account.confirm_delete_modal.action_btn }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <confirm-delete-account-modal :user="user" :deleteData="formData" />
        </div>
    </layout>
</template>

<script>
    import Layout from "@/components/layouts/Layout";
    import Breadcrumb from "@/components/breadcrumb/Breadcrumb";

    import app_static from '@/app_static.env'
    import utils from "@/libs/utils"
    import store from '@/store/index'
    import api from "@/libs/data_api"


    import ConfirmDeleteAccountModal from '@/components/app/ConfirmDeleteAccountModal'


    export default {
        name: 'Account',
        components: {Breadcrumb, Layout, ConfirmDeleteAccountModal},
        data() {
            return {
                app_static: app_static,
                formData: {
                    email: '', 
                    first_name: '', 
                    last_name: '', 
                    keepupdated: true, 
                    comments: "", 
                    reason: '',
                }
            }
        },
        computed: {
            
            deleteReasons() {
                utils.console.log("delete reasons: ", app_static.account.delete_reasons);
                // return app_static.acccount && app_static.acccount.delete_reasons ? app_static.acccount.delete_reasons : [];
                return app_static.account.delete_reasons;
            },
            firstname() {
                return this.user && this.user.user ? this.user.user.first_name_text : '';
            }, 
            lastname() {
                return this.user && this.user.user ? this.user.user.last_name_text : '';
            }, 
            email() {
                try {
                    return this.user.user.authentication.email.email;    
                } catch (error) {
                    return '';
                }
            }, 
            user() {
                return store.state.user.data? store.state.user.data : null;
            }
        }, 
        watch: {
            user(newUser, oldUser) {
                // utils.console.log("account page new user: ", newUser);
                
                this.formData.email = newUser && newUser.user ? newUser.user.email_text : ""; 
                this.formData.first_name = newUser && newUser.user ? newUser.user.first_name_text : ""; 
                this.formData.last_name = newUser && newUser.user ? newUser.user.last_name_text : ""; 
            }
        },
        mounted() {
            // utils.console.log("account page mounted user: ", this.user); 
            // utils.console.log("account page mounted user auth email: ", this.user.user.authentication.email.email); 

            if (this.firstname) {
                this.formData.first_name = this.firstname;
            }

            if (this.lastname) {
                this.formData.last_name = this.lastname;
            }

            if (this.email) {
                this.formData.email = this.email;
            }

            this.formData.reason = app_static.account.delete_reasons[4].value;
        }, 
        methods: {
            deleteAccount() {
                utils.console.log("call Delete Account: ", this.formData);
            }, 
            confirmDeleteAccount() {
                utils.console.log("confirmDeleteAccount: ", this.formData);

            }, 
            reasonChangeHandler(option) {
                utils.console.log("delete reason changed: ", option);
            }, 
            resetPassword() {
                utils.console.log("submit reset pasword"); 
                api.user
                    .resetPassword(this.formData.email)
                    .then((resp) => {
                        // utils.console.log("token still valid user plan: ", resp);
                        // utils.console.log("name change successful:", resp);
                        
                        self.$notify({
                            title: "<h3>Success</h3>",
                            text: "<b>Reset password request sent!</b>", 
                            type: 'success',
                            duration: 2000
                        });

                    })
                    .catch((error) => {
                        utils.console.log("ERRRR:: ", error);
                        let mesg = "Something went wrong, please try again!";
                        if (error.response && error.response.data && error.response.data.message) {
                            mesg = error.response.data.message;
                        }

                        this.$notify({
                            title: "<h2>Error</h2>",
                            text: "<h4>" +mesg+ "</h4>", 
                            type: 'error',
                            duration: 2000
                        });
                    });
            }, 

            savePersonalInfo() {
                if (! this.formData || ! this.formData.first_name 
                || ! this.user.user_id ) {
                    return; 
                }

                // utils.console.log("save personal info: ", this.formData)
                // update personal info

                let self= this;
                this.formData['user'] = this.user.user_id;
                
                api.user
                    .changeNames(this.formData)
                    .then((resp) => {
                        // utils.console.log("token still valid user plan: ", resp);
                        // utils.console.log("name change successful:", resp);
                        
                        store.set('user/data@user.first_name_text', resp.first_name_text);
                        store.set('user/data@user.last_name_text', resp.last_name_text);
                        
                        if (this.user) {
                            utils.console.log("update local store user : ", this.user);
                            utils.localStore.user.set(this.user);
                        }
                        
                        self.$notify({
                            title: "<h3>Success</h3>",
                            text: "<b>Name change called!</b>", 
                            type: 'success',
                            duration: 2000
                        });

                    })
                    .catch((error) => {
                        utils.console.log("ERRRR:: ", error);
                        let mesg = "Something went wrong, please try again!";
                        if (error.response && error.response.data && error.response.data.message) {
                            mesg = error.response.data.message;
                        }

                        this.$notify({
                            title: "<h2>Error</h2>",
                            text: "<h4>" +mesg+ "</h4>", 
                            type: 'error',
                            duration: 2000
                        });
                    });
                
            }, 
            previewImage(e, id) {
                if (e.target.files && e.target.files.length > 0) {
                    const file = e.target.files[0];
                    document.querySelector(`#${id}`).setAttribute('src', URL.createObjectURL(file));
                }
            }
        }
    }
</script>