import { make } from 'vuex-pathify'

const state = {
  // list of leases that the user owns
  list: [], 
  by_id: {},
}

// make all mutations
const mutations = make.mutations(state)

const actions = {
  // automatically create only `setItems()` action
  ...make.actions(state)
}

const getters = {
  // make all getters (optional)
  ...make.getters(state),
}

export default {
  namespaced: true, // add this if in module
  state,
  getters,
  mutations,
  actions,
}