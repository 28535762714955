import Vue from 'vue'
import Vuex from 'vuex'
import pathify, { make } from 'vuex-pathify'


// Modules
import team from './team'
import author from './author'
import course from './course'
import nft from './nft'
import partner from './partner'
import user from './user'
import player from './player'
import content from './content'
import playlist from './playlist'
import keyword from './keyword'
import buyonwebmodal from './buyonwebmodal'

// Vue.use(Vuex)

export default new Vuex.Store({

  // use the plugin
  plugins: [
    pathify.plugin
  ],
  modules: {
    team,
    author,
    course,
    nft,
    partner,
    user,
    player,
    content,
    playlist,
    keyword,
    buyonwebmodal
  },
  strict: process.env.DEV,
})
