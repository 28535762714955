export default 
{
    "data": {
        "status": "success",
        "response": {
            "data": [
                {
                    "category_text": "section",
                    "subtitle_text": null,
                    "keyword_text": "stress",
                    "label_text": "Stress",
                    "priority_number": 1,
                    "_id": "1676041871288x717315253453224000"
                },
                {
                    "category_text": "section",
                    "subtitle_text": null,
                    "keyword_text": "meditation",
                    "label_text": "Meditation",
                    "priority_number": 1,
                    "_id": "1676042239263x671243341200145900"
                },
                {
                    "category_text": "pills",
                    "subtitle_text": null,
                    "keyword_text": "stress",
                    "label_text": "Stress",
                    "priority_number": 1,
                    "_id": "1676042657714x140306059553137570"
                },
                {
                    "category_text": "section",
                    "subtitle_text": null,
                    "keyword_text": "self-care",
                    "label_text": "Self Care",
                    "priority_number": 2,
                    "_id": "1676041888658x626545256757117300"
                },
                {
                    "category_text": "section",
                    "subtitle_text": null,
                    "keyword_text": "relationships",
                    "label_text": "Relationships",
                    "priority_number": 2,
                    "_id": "1676042149803x884472775858423000"
                },
                {
                    "category_text": "section",
                    "subtitle_text": null,
                    "keyword_text": "sleep",
                    "label_text": "Sleep",
                    "priority_number": 2,
                    "_id": "1676042180106x603773454614901000"
                },
                {
                    "category_text": "pills",
                    "subtitle_text": null,
                    "keyword_text": "self-care",
                    "label_text": "Self Care",
                    "priority_number": 2,
                    "_id": "1676042657715x181332975152839650"
                },
                {
                    "category_text": "section",
                    "subtitle_text": null,
                    "keyword_text": "workplace",
                    "label_text": "Workplace",
                    "priority_number": 3,
                    "_id": "1676042204804x459138160731574800"
                },
                {
                    "category_text": "section",
                    "subtitle_text": null,
                    "keyword_text": "podcast",
                    "label_text": "Podcast",
                    "priority_number": 3,
                    "_id": "1676042311262x612652766725433600"
                },
                {
                    "category_text": "pills",
                    "subtitle_text": null,
                    "keyword_text": "emotions",
                    "label_text": "Emotions",
                    "priority_number": 4,
                    "_id": "1676042657715x448905970055439400"
                },
                {
                    "category_text": "pills",
                    "subtitle_text": null,
                    "keyword_text": "sleep",
                    "label_text": "Sleep",
                    "priority_number": 5,
                    "_id": "1676042657715x183693911535406600"
                },
                {
                    "category_text": "pills",
                    "subtitle_text": null,
                    "keyword_text": "relationships",
                    "label_text": "Relationships",
                    "priority_number": 5.5,
                    "_id": "1676042657715x416739869483986100"
                },
                {
                    "category_text": "pills",
                    "subtitle_text": null,
                    "keyword_text": "body",
                    "label_text": "Body",
                    "priority_number": 6,
                    "_id": "1676042657715x995746330831065900"
                },
                {
                    "category_text": "pills",
                    "subtitle_text": null,
                    "keyword_text": "workplace",
                    "label_text": "Workplace",
                    "priority_number": 7,
                    "_id": "1676042657715x167723891791297760"
                },
                {
                    "category_text": "pills",
                    "subtitle_text": null,
                    "keyword_text": "podcast",
                    "label_text": "Podcast",
                    "priority_number": 8,
                    "_id": "1676042657715x360886041259886200"
                },
                {
                    "category_text": "pills",
                    "subtitle_text": null,
                    "keyword_text": "meditation",
                    "label_text": "Meditation",
                    "priority_number": 9,
                    "_id": "1676042657715x957566680240864000"
                }
            ]
        }
    },
    "status": 200,
    "statusText": "OK",
    "headers": {
        "content-type": "application/json"
    },
    "config": {
        "transitional": {
            "silentJSONParsing": true,
            "forcedJSONParsing": true,
            "clarifyTimeoutError": false
        },
        "transformRequest": [
            null
        ],
        "transformResponse": [
            null
        ],
        "timeout": 0,
        "xsrfCookieName": "XSRF-TOKEN",
        "xsrfHeaderName": "X-XSRF-TOKEN",
        "maxContentLength": -1,
        "maxBodyLength": -1,
        "env": {
            "FormData": null
        },
        "headers": {
            "Accept": "application/json, text/plain, */*"
        },
        "baseURL": "https://blisspot.io/api/1.1/obj",
        "withCredentials": false,
        "credentials": false,
        "params": {},
        "method": "get",
        "url": "https://blisspot.io/api/1.1/wf/api-keywords"
    },
    "request": {}
}