import data_api from "../libs/data_api";
import store from '@/store/index'
import utils from "./utils"
import app_static from '@/app_static.env';
import router from '@/router'


data_api.init();

export const init = () => {
    return new Promise((resolve, reject) => {

        // if token invalid, reset user and navigate to login page
        if (app_static.buildForApp) {

            utils.localStore.user.init();

            utils.initDummyGetSignedUrl(
                function (resp) {
                    utils.console.log("init user init dummy get signed url works, token works");
                    resolve(resp);
                }, 
                function (err) {
                    utils.console.log("init user init dummy get signed url err, token err");
                    store.set("user/data", {});
                    router.push({ name: 'Login', query: { redirect: router.currentRoute.value.path } });
                    resolve();
                }
            )

            if (store.state.user.data && store.state.user.data.user_id) {
                utils.console.log("init user store.state.user.data.user_id ", store.state.user.data.user_id)
                data_api.user
                    .plan({'user': store.state.user.data.user_id})
                    .then((resp) => {
                        utils.console.log("token still valid user plan: ", resp);
                        store.set("user/plan", resp);
                        resolve(resp);
                    })
                    .catch((error) => {
                        console.error("init user local faild: ", error)
                        // reset the local store
                        // utils.localStore.user.set({});
                        resolve();
                    });
            
            }
            // utils.console.log("init user local store user: ", user);

            // if (user && user.token) {
                
                // utils.console.log("init user local store set auth token: ", user.token);

                // data_api.auth.setAuthToken(user.token);

                // utils.console.log("init user local store auth user");
                
                // data_api.auth
                //     .user({'user_id': user.user_id})
                //     .then((resp) => {
                //         utils.console.log("token still valid: ", resp);
                //         // store.set("user/data", user);
                //         // utils.console.log("store init user local init: ", user);
                //         resolve(user);
                //     })
                //     .catch((error) => {
                //         console.error("init user local faild: ", error)
                //         // reset the local store
                //         utils.localStore.user.set({});
                //         resolve();
                //     });
                
                // store.set("user/data", user);
            // } else {
            //    resolve(user);  
            // }

        } else {
            resolve();
        }
    })
}

// export const init = () => {

//     new Promise((resolve, reject) => {

//         // if user object in local storage and token still valid
//         if (app_static.buildForApp) {
            
//             let user = utils.localStore.user.get();
            
//             // if build 4 app, try to get the user data first based on the local store token
//             // if its not working then dont log user in and also wipe local storage data.   
//             utils.console.log("init user local store user: ", user);

//             if (user && user.token) {
                
//                 utils.console.log("init user local store set auth token: ", user.token);

//                 data_api.auth.setAuthToken(user.token);

//                 utils.console.log("init user local store auth user");
                
//                 data_api.auth
//                     .user({'user_id': user.user_id})
//                     .then((resp) => {
//                         utils.console.log("init user local resp: ", resp);
//                         store.set("user/data", user);
//                         utils.console.log("store init user local init: ", user);
//                         resolve(user);
//                     })
//                     .catch((error) => {
//                         console.error("init user local faild: ", error)
//                         // reset the local store
//                         utils.localStore.user.set({});
//                         resolve({});
//                     });
                
//                 // store.set("user/data", user);
//             } else {
//                resolve(user);  
//             }

//         } else {
//             resolve({});
//         }
//     }
// }

