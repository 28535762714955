<template>
    <div :class="`product-style-one ${productStyleClass}`">
        <div class="card-thumbnail">
            <router-link to="#">
                <img class="img_min_width" 
                :src="productDate.banner_tile_image" 
                :alt="productDate.first_text + ' ' + productDate.last_text" 
                >
                <!-- @load="$emit('handleImageLoad')"
                 -->
            </router-link>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'ProductCard',
        components: {},
        props: {
            productDate: {},
            productStyleClass: {
                type: String
            },
            showPlaceBid: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            '$route': function (to, from) {
                this.closeModal()
            }
        },
        methods: {
            closeModal() {
                const modals = document.querySelectorAll('.modal-backdrop');

                if (modals.length) {
                    modals.forEach(item => {
                        item.remove();
                    })
                }
            }
        }
    }
</script>