<template>
    <layout>
      <!-- <breadcrumb title="Blisspot" current="home"/> -->
        <div>
        <!-- Start Banner Area -->
        <!-- <div class="slider-one rn-section-gapTop">
            <div class="container">
                <h2 class="title" data-sal-delay="200" data-sal="" data-sal-duration="800">
                  Welcome
                </h2>
                <button @click="togglePlayer" class="btn btn-lg btn-primary"> Show Player</button>

            </div>
        </div> -->
        <!-- End Banner Area -->

        
        <!-- course Start -->
        <!-- <course-player /> -->
        <course />
        <!-- course End -->

        <!-- <player :image="course.image" name="test"/> -->
<!--         
      <player :image="course && course.banner_tile_image ? course.banner_tile_image: ''" name="player"/> -->

      <!-- <player name="player"/> -->
        <!-- <share-modal />
        <report-modal /> -->
    </div>
    </layout>
</template>



<script>

import store from '@/store/index'

import Layout from '@/components/layouts/Layout'
// import ExploreLiveCarousel from '@/components/explore/ExploreLiveCarousel'
// import ServiceCard from '@/components/service/ServiceCard'
// import ProductCard from '@/components/product/ProductCard'
import SalScrollAnimationMixin from '@/mixins/SalScrollAnimationMixin'
// import ShareModal from '@/components/modal/ShareModal'
// import ReportModal from '@/components/modal/ReportModal'
// import ExploreFilter from '@/components/explore/ExploreFilter'
// import ServiceStyleOne from '@/components/service/ServiceStyleOne'
// import SellerStyleOne from '@/components/seller/SellerStyleOne'
// import CollectionStyleOne from '@/components/collection/CollectionStyleOne'
// import ExploreNewestItem from '@/components/explore/ExploreNewestItem'

import app_static from '@/app_static.env'

// import VideoBanner from '@/components/app/VideoBanner'
// import IntroBanner from '@/components/app/IntroBanner'

// import Team from '@/components/app/Team'
// import Author from '@/components/app/Author'
import Course from '@/components/app/Course'

// import CoursePlayer from '@/components/app/CoursePlayer'
// import Nft from '@/components/app/Nft'
// import FounderBanner from '@/components/app/FounderBanner'
// import Partner from '@/components/app/Partner'
// import JoinUsBanner from '@/components/app/JoinUsBanner'

import utils from "@/libs/utils"

import Player from '@/components/app/Player'

export default {
    name: 'Main',
    components: {
    // ExploreNewestItem,
    // CollectionStyleOne,
    // SellerStyleOne,
    // ServiceStyleOne,
    // ExploreFilter,
    // ReportModal,
    // ShareModal,
    // ProductCard,
    // ServiceCard,
    // ExploreLiveCarousel,
    Layout,
    // Team,
    // Author,
    Course,
    // CoursePlayer,
    // Nft,
    // VideoBanner,
    // IntroBanner,
    // FounderBanner,
    // Partner,
    // JoinUsBanner
    Player
},

    mounted() {
        // utils.console.log("mounted");
        // init_team.init();
        document.title = "Blisspot";
    },
    methods: {
      togglePlayer() {
        utils.console.log("toggle player: ", store.state.player.showPlayer);
        store.set("player/showPlayer", ! store.state.player.showPlayer);
      }
    }, 
    data() {
        return {
            lang:"en",
            // banner_img: require('@/assets/images/banner/authors_corner.webp'),
            app_static: app_static,

            course: {
              image: "" 
            }
        }
    },
    mixins: [SalScrollAnimationMixin]
}
</script>