/**
 * bubble data api
 */

import endpoints from '@/data_api_endpoints.env'
import axios from "axios";
import app_static from '../app_static.env';
import utils from "./utils"
import store from '@/store/index'

let cache = {
  author_list: require('@/cache.author_list'),
  course_list: require('@/cache.course_list'),
  nft_list: require('@/cache.nft_list'),
  partner_list: require('@/cache.partner_list'),
  team_list: require('@/cache.team_list'), 
  keyword_list: require('@/cache.keyword_list'), 
  
}


// console.log("cache author list: ", cache.author_list);

var api = {
  authClient: null,
  axiosObj: null,
  config: {
    // required to handle the CSRF token
    withCredentials: false,
    credentials: false,
    headers: {
      // Overwrite Axios's automatically set Content-Type
      // 'Content-Type': 'application/json'
    }, 
  },
  auth_headers: {
    'Authorization': null
  }, 

  urls: endpoints,
  fullUrl(url) {
    // utils.console.log("full url: ", this.base_url + url);
    return this.base_url + url;
  },
  // init(axiosObj, url) {
  init(url) {
    this.axiosObj = axios;
    // this.base_url = url ? url : api.base_url;
    // utils.console.log("base url: ", this.base_url);
    // this.newClient(this.base_url);
    this.newClient(api.urls.base_url);
  },
  newClient(url) {
    this.authClient = this.axiosObj.create({
      baseURL: url,
      withCredentials: this.config.withCredentials, // required to handle the CSRF token
      credentials: this.config.credentials,
    });
    return this.authClient;
  },


  // content api
  content: {
    getSignedUrl(payload) {


      return new Promise((resolve, reject) => {
        if (! api.auth_headers.Authorization) {
          reject("No Auth");
        }
        
        let url = api.urls.wf.getsignedwasabiurlCDN;
        
        // utils.console.log("getSignedUrl url: ", url);
        // utils.console.log("getSignedUrl payload: ", payload);

        api.authClient.post(url,payload, { headers: api.auth_headers })
          .then(function (resp) {
            // utils.console.log("getSignedUrl resp: ", resp)
            if (resp.status == 200) {
              
              resolve(resp);
            } else {
              reject(resp);
            }
          })
          .catch(error => {
            utils.console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    }
  }, 
  
  user: {
    delete(payload) {
      
      return new Promise((resolve, reject) => {
        if (! payload || ! payload.user ) {
          reject("invalid param 4 user delete: ", payload);
        }

        let url = api.urls.wf.delete_user;
        utils.console.log("user delete_user url: ", url);
        
        api.authClient.post(
          url, payload,
          {
            headers: api.auth_headers
          })
          .then(function (resp) {
            utils.console.log("user delete_user resp: ", resp)
            if (resp.status == 200) {
              resolve(resp.data.response.data);
            } else {
              reject(resp);
            }
          })
          .catch(error => {
            utils.console.log("ERRRR: ", error.message);
            reject(error);
          });

      });
    },  


    changeNames(payload) {
      
      return new Promise((resolve, reject) => {
        if (! payload ) {
          reject("invalid param 4 user changenNames: ", payload);
        }

        let url = api.urls.wf.change_names;
        utils.console.log("user changenNames url: ", url);
        
        api.authClient.post(
          url, payload,
          {
            headers: api.auth_headers
          })
          .then(function (resp) {
            utils.console.log("user changenNames resp: ", resp)
            if (resp.status == 200) {
              resolve(resp.data.response.data);
            } else {
              reject(resp);
            }
          })
          .catch(error => {
            utils.console.log("ERRRR: ", error.message);
            reject(error);
          });

      });
    },  

    promo(payload) {
      return new Promise((resolve, reject) => {
        if (! payload || ! payload.user) {
          reject("invalid param 4 user promo: ", payload);
        }

        let url = api.urls.wf.user_promo;
        utils.console.log("user_promo url: ", url);
        
        api.authClient.post(
          url, payload,
          {
            headers: api.auth_headers
          })
          .then(function (resp) {
            utils.console.log("user_promo resp: ", resp)
            if (resp.status == 200) {
              resolve(resp.data.response.data);
            } else {
              reject(resp);
            }
          })
          .catch(error => {
            utils.console.log("ERRRR: ", error.message);
            reject(error);
          });

      });
    },

    plan(payload) {
      
      return new Promise((resolve, reject) => {
        if (! payload || ! payload.user) {
          reject("invalid param 4 user plan: ", payload);
        }

        let url = api.urls.wf.user_plan;
        utils.console.log("user plan url: ", url);
        
        api.authClient.post(
          url, payload,
          {
            headers: api.auth_headers
          })
          .then(function (resp) {
            utils.console.log("user plan resp: ", resp)
            if (resp.status == 200) {
              resolve(resp.data.response.data);
            } else {
              reject(resp);
            }
          })
          .catch(error => {
            utils.console.log("ERRRR: ", error.message);
            reject(error);
          });

      });
    }, 

  }, 


  // auth
  auth: {
    resetPassword(email) {
      return new Promise((resolve, reject) => {
        let url = api.urls.wf.reset_password;

        utils.console.log("resetPassword url: ", url);
        utils.console.log("resetPassword payload: ", email);

        api.authClient.post(url,{'email': email})
          .then(function (resp) {
            utils.console.log("resetPassword resp: ", resp)
            if (resp.status == 200) {
              resolve(resp);
            } else {
              reject(resp);
            }
          })
          .catch(error => {
            utils.console.log("ERRRR: resetPassword: ", error.message);
            reject(error);
          });
      });
    }, 

    user(payload) {
        
        return new Promise((resolve, reject) => {
          if (! payload || ! payload.user_id) {
            reject("invalid param user id: ", payload);
          }

          // if (app_static.use_cache.partner_list) {
          //   utils.console.log("return user cache: ", cache.user_list);
          //   resolve(cache.partner_list.default.data.response.data);
          // }
            
          let url = api.urls.wf.user;

          utils.console.log("auth user url: ", url);
          
          api.authClient.post(url, payload)
            .then(function (resp) {
              utils.console.log("auth user resp: ", resp)
              if (resp.status == 200) {
                resolve(resp.data.response.data);
              } else {
                reject(resp);
              }
            })
            .catch(error => {
              utils.console.log("ERRRR: ", error.message);
              reject(error);
            });
  
        });
  
    }, 

    setAuthToken(token) {
      api.auth_headers.Authorization = 'Bearer ' + token;
    }, 

    signup(payload) {
      return new Promise((resolve, reject) => {
        let url = api.urls.wf.signup;

        utils.console.log("signup url: ", url);
        utils.console.log("signup payload: ", payload);

        api.authClient.post(url,payload)
          .then(function (resp) {
            utils.console.log("signup resp: ", resp)
            if (resp.status == 200) {
              // set auth header for future auth requests
              if (resp.data.response && resp.data.response.token) {
                // api.auth_headers.Authorization = 'Bearer ' + resp.data.response.token;
                api.auth.setAuthToken(resp.data.response.token);
              }
              
              // update user local storage
              let userData = resp.data.response;
              userData.token_timestamp = Date.now();
              // store a copy of the user
              utils.localStore.user.set(userData);
              // update store
              store.set("user/data", userData);

              resolve(userData);
            } else {
              reject(resp);
            }
          })
          .catch(error => {
            utils.console.log("ERRRR: signup: ", error.message);
            reject(error);
          });
      });
    }, 
    login(email, password, remember) {
      return new Promise((resolve, reject) => {
        let url = api.urls.wf.login;
        
        let payload = {
          email: email, 
          password: password, 
          remember: remember
        }

        utils.console.log("login url: ", url);
        utils.console.log("login payload: ", payload);

        api.authClient.post(url,payload)
          .then(function (resp) {
            utils.console.log("login resp: ", resp)
            if (resp.status == 200) {
              // set auth header for future auth requests
              if (resp.data.response && resp.data.response.token) {
                // api.auth_headers.Authorization = 'Bearer ' + resp.data.response.token;
                api.auth.setAuthToken(resp.data.response.token);
              }
              
              // update user local storage
              let userData = resp.data.response;
              userData.token_timestamp = Date.now();
              // store a copy of the user
              utils.localStore.user.set(userData);
              // update store
              store.set("user/data", userData);

              resolve(userData);
            } else {
              reject(resp);
            }
          })
          .catch(error => {
            utils.console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    }
  }, 
  
  // partner 
  register: {
    email: function (email, type) {
      return new Promise((resolve, reject) => {
        let url = api.urls.wf.register_email;
        utils.console.log("register_email url: ", url);

        let payload = {
          email: email, 
          type: type
        }

        api.authClient.post(url,payload)
          .then(function (resp) {
            utils.console.log("register_email resp: ", resp)
            if (resp.status == 200) {
              resolve(resp);
            } else {
              reject(resp);
            }
          })
          .catch(error => {
            utils.console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
  },

  // partner 
  partner: {
    index: function () {
      return new Promise((resolve, reject) => {

        if (app_static.use_cache.partner_list) {
          utils.console.log("return partner cache: ", cache.partner_list);
          resolve(cache.partner_list.default.data.response.data);
        }

        let url = api.urls.wf.partner_list;
        utils.console.log("partner index url: ", url);
        api.authClient.get(url)
          .then(function (resp) {
            utils.console.log("partner list: ", resp)
            if (resp.status == 200) {
              resolve(resp.data.response.data);
            } else {
              reject(resp);
            }
          })
          .catch(error => {
            utils.console.log("ERRRR:: ", error.message);
            reject(error);
          });

      });

    },
  },

  // nfts 
  keyword: {
    index: function (category) {
      return new Promise((resolve, reject) => { 

        if (app_static.use_cache.keyword_list) {
          utils.console.log("return keyword_list cache: ", cache.keyword_list);
          resolve(cache.keyword_list.default.data.response.data);
        }

        let url = api.urls.wf.keyword_list;
        utils.console.log("keyword_list index url: ", url);

        let params = {
          params: {}
        }
        if (category) {
          params.params['category'] = category
        }

        api.authClient.get(url, params)
          .then(function (resp) {
            utils.console.log("keyword_list: ", resp)
            if (resp.status == 200) {
              resolve(resp.data.response.data);
            } else {
              reject(resp);
            }
          })
          .catch(error => {
            utils.console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
  },

  // nfts 
  nft: {
    index: function () {
      return new Promise((resolve, reject) => {


        if (app_static.use_cache.nft_list) {
          utils.console.log("return nft_list cache: ", cache.nft_list);
          resolve(cache.nft_list.default.data.response.data);

        }
          

        let url = api.urls.wf.nft_list;
        utils.console.log("nft index url: ", url);
        api.authClient.get(url)
          .then(function (resp) {
            utils.console.log("nft list: ", resp)
            if (resp.status == 200) {
              resolve(resp.data.response.data);
            } else {
              reject(resp);
            }
          })
          .catch(error => {
            utils.console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
  },

  // course 
  course: {

    // index will replace all the data, more will add onto existing list
    index: function (start, keyword) {
      return new Promise((resolve, reject) => {

        let params = {
          params: {}
        }
        if (start) {
          params.params['start'] = start
        }
        if (keyword) {
          params.params['keyword'] = keyword
        }

        let url = api.urls.wf.course_list;
        utils.console.log("course index url: ", url);
        api.authClient.get(url, params)
          .then(function (resp) {
            utils.console.log("course list: ", resp)
            if (resp.status == 200) {
              let data = resp.data.response.data;
              keyword ? store.set('course/search_text', keyword) : '';
              store.set("course/list", data);      
              // utils.console.log("store course list after init: ", store.state.course.list);
              store.set("course/by_id", utils.sort.listById(data, '_id'));
              // utils.console.log("store course by id after init: ", store.state.course.by_id);
              resolve(data);

            } else {
              reject(resp);
            }
          })
          .catch(error => {
            utils.console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },

    more: function () {
      return new Promise((resolve, reject) => {

        let params = {
          params: {}
        }
        
        utils.console.log("data api course more current list: ", store.state.course.list);
        utils.console.log("data api course more current list length: ", store.state.course.list.length);

        if ( store.state.course.list && store.state.course.list.length > 0 ) {
          params.params['start'] = store.state.course.list.length + 1;
        }
        if ( store.state.course.search_text ) {
          params.params['keyword'] = store.state.course.search_text
        }

        let url = api.urls.wf.course_list;
        utils.console.log("course index url: ", url, params);
        api.authClient.get(url, params)
          .then(function (resp) {
            utils.console.log("course list: ", resp)
            if (resp.status == 200) {
              let data = resp.data.response.data; 
              utils.console.log("data api coruse more resp data: ", data);

              // append to course list
              store.set("course/list", utils.push.toListByAppend(store.state.course.list, data));      
              
              utils.console.log("store course list after init: ", store.state.course.list);

              // store.set("course/by_id", utils.sort.listById(data, '_id'));
              let courseByIdFinal = utils.push.toListById(store.state.course.by_id, '_id', data); 
              store.set("course/by_id", courseByIdFinal);
              resolve(courseByIdFinal);

            } else {
              reject(resp);
            }

          })
          .catch(error => {
            utils.console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },

    index_old: function () {
      return new Promise((resolve, reject) => {

        if (app_static.use_cache.course_list) {
          utils.console.log("return course_list cache: ", cache.course_list);
          resolve(cache.course_list.default.data.response.data);
        }

        let url = api.urls.wf.course_list;
        utils.console.log("course index url: ", url);
        api.authClient.get(url)
          .then(function (resp) {
            utils.console.log("course list: ", resp)
            if (resp.status == 200) {
              resolve(resp.data.response.data);
            } else {
              reject(resp);
            }

          })
          .catch(error => {
            utils.console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },

    data(payload) {
      return new Promise((resolve, reject) => {
        if (! api.auth_headers.Authorization) {
          // reject("No Auth");

          // testing 
          api.auth_headers.Authorization = 'Bearer 1679049812177x826303296578556600';
        }

        let url = api.urls.wf.course_data;
        
        utils.console.log("course_data url: ", url);
        utils.console.log("course_data payload: ", payload);

        api.authClient.post(url,payload, { headers: api.auth_headers })
          .then(function (resp) {
            utils.console.log("course_data resp: ", resp)
            if (resp.status == 200) {
              
              resolve(resp);
            } else {
              reject(resp);
            }
          })
          .catch(error => {
            utils.console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    }
    
  },

  // author 
  author: {
    index: function () {
      return new Promise((resolve, reject) => {

        if (app_static.use_cache.author_list) {
          utils.console.log("return author_list cache: ", cache.author_list);
          resolve(cache.author_list.default.data.response.data);
        }

        let url = api.urls.wf.author_list;
        utils.console.log("author index url: ", url);
        api.authClient.get(url)
          .then(function (resp) {
            utils.console.log("author list: ", resp)
            if (resp.status == 200) {
              resolve(resp.data.response.data);
            } else {
              reject(resp);
            }

          })
          .catch(error => {
            utils.console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
  },


  // team 
  team: {
    index: function () {
      return new Promise((resolve, reject) => {


        if (app_static.use_cache.team_list) {
          utils.console.log("return team_list cache: ", cache.team_list);
          resolve(cache.team_list.default.data.response.results);
        }


        let url = api.urls.links.team_list;
        api.authClient.get(url)
          .then(function (resp) {
            utils.console.log("team list: ", resp)
            if (resp.status == 200) {
              resolve(resp.data.response.results);
            } else {
              reject(resp);
            }

          })
          .catch(error => {
            utils.console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
    },



}

export default api;

