<template>
    <div class="buyonwebmodal rn-popup-modal placebid-modal-wrapper modal fade" 
        id="BuyOnWebModal" tabindex="-1" 
        
        >
        <button @click="handleCloseModal()" 
            type="button" 
            class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="feather-x text-danger " style="font-size: 30px;"/>
        </button>
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" 
            data-mdb-backdrop="true"
        >
            <div class="modal-content ">
                <div class="modal-header">
                    <h1 class="modal-title">{{app_static.buyonwebmodal.title}}</h1>
                </div>
                <div class="modal-body pl--0 pr--0" >
                    <h4>{{app_static.buyonwebmodal.subtitle}}</h4>

                    <div class="d-flex justify-content-between">
                        <button class="btn btn-primary">
                            {{app_static.buyonwebmodal.a_btn_text}}
                        </button>
                        
                        <!-- <a @click="handleCloseModal()" 
                            target="_blank" 
                            :href="buyOnWebLink"
                            class="btn btn-primary">{{app_static.buyonwebmodal.a_btn_text}}
                        </a> -->

                        <button @click="handleCloseModal()" 
                            type="button" 
                            class="btn btn-sm btn-secondary" data-bs-dismiss="modal" aria-label="Close">
                            close 
                        </button>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import app_static from '@/app_static.env'
    import utils from "@/libs/utils"
    import api from "@/libs/data_api"
    import store from '@/store/index'
    import useVuelidate from '@vuelidate/core'
    import {required, email} from '@vuelidate/validators'
    import endpoints from '@/data_api_endpoints.env'

    export default {
        name: 'RegFounderEmailModal',
        data() {
            return {
                app_static: app_static,
                modalElemId: 'BuyOnWebModal'
            }
        },
        validations() {
            return {
                formData: {
                    email: {required, email}
                }
            }
        },
        computed: {
          buyOnWebLink() {
            return endpoints.links.buyOnWebLink;
          },
          show() {
            return store.state.buyonwebmodal.show; 
          }
        }, 
        watch: {
            show(newShow, oldShow) {
                utils.console.log("buy on web modal showModal: ", newShow); 
                if (newShow == true) {
                    this.showModal();
                } else {
                    this.hideModal();
                }
            }
        },  
        mounted() {
            // this.v$.$validate();
        },  
        methods: {
            
            hideModal() {
                let modalElm = document.getElementById(this.modalElemId);                         
                modalElm.classList.remove('show');
                modalElm.classList.remove('d-block');
            }, 

            showModal() {
                let modalElm = document.getElementById(this.modalElemId);                         
                modalElm.classList.add('show');
                modalElm.classList.add('d-block');
            }, 

            handleCloseModal() {
                store.set('buyonwebmodal/show', false);
            }, 

            handleRegister() {
                this.v$.$validate();
                // utils.console.log("handle Register founder: ", this.emailInput);

                if (!this.v$.$error) {
                    api.register.email(this.formData.email, app_static.founder.register_email_type)
                        .then((resp) => {
                            utils.console.log("register founder resp: ", resp);
                            this.$notify({
                                title: "<h2>Success</h2>",
                                text: "<b>Thank you for your interest!  Stay tuned!</b>", 
                                type: 'success',
                                duration: 2000
                            });
                        })
                        .catch((error) => {
                            utils.console.error("author index faild: ", error);
                            this.$notify({
                                title: "<h2>Error</h2>",
                                text: "<b>Somethig went wrong, please try again!</b>", 
                                type: 'error',
                                duration: 3000
                            });
                        });
                }
            }
        }
    }
</script>