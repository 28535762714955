<template>
    <layout>
      <breadcrumb title="Search" current="Search"/>


        <!-- Start Banner Area -->
        <!-- <div class="slider-one rn-section-gapTop">
            <div class="container">
                <h2 class="title" data-sal-delay="200" data-sal="" data-sal-duration="800">
                  Search
                </h2>

            </div>
        </div> -->
        <!-- End Banner Area -->

        
        <!-- Search Input Start -->
        <div class="container mt--50 ">

          <div class="form-group search_view_search">
            <button @click="search(searchText)"
                    :disabled="searchText.length < app_static.minSearchTextLength"
                    class="search_input_icon_btn">
              <i class="feather-search"/>
            </button>
            <input class="search_input" type="text" placeholder="Search ..." v-model="searchText">
          </div>

        </div>
        <!-- Search Input -->

        <!-- Keywords Cards -->
        <div class="container mt--10">
          <div class="row">
            <div class="button-group isotop-filter filters-button-group d-flex justify-content-start justify-content-lg-end mt_md--10 mt_sm--10">
              <button @click="selectKeyword()" data-filter="*" :class="isWordSelected() ? 'is-checked': ''">All</button>
              <button @click="selectKeyword(word)" :class="isWordSelected(word) ? 'is-checked': ''"
              v-for="(word, index) in keywords" :key="index"
              >{{word.label_text}}</button>
              </div>
          </div>
        </div>
        <!-- Keywords Input -->

        <!-- <player :image="course.image" name="test"/> -->
        <!-- <div class="container mt-20">
          <div class="row g-5">
            <div v-show="true" class="col-lg-4 col-md-6 col-sm-6 col-12"
                 v-for="(item, index) in search_items"
                 :key="'search-item-'+index"
                 data-sal=""
                 :data-sal-delay="150+index"
                 data-sal-duration="800">
                <course-card
                    :product-date="item"
                    product-style-class="no-overlay"
                    @click="goToCourse(item)"
                />
            </div>
        </div>
        </div> -->

        <!-- <share-modal />
        <report-modal /> -->
    </layout>
</template>



<script>

import store from '@/store/index'

import Layout from '@/components/layouts/Layout'
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";

// import ExploreLiveCarousel from '@/components/explore/ExploreLiveCarousel'
// import ServiceCard from '@/components/service/ServiceCard'
// import ProductCard from '@/components/product/ProductCard'
import SalScrollAnimationMixin from '@/mixins/SalScrollAnimationMixin'
// import ShareModal from '@/components/modal/ShareModal'
// import ReportModal from '@/components/modal/ReportModal'
// import ExploreFilter from '@/components/explore/ExploreFilter'
// import ServiceStyleOne from '@/components/service/ServiceStyleOne'
// import SellerStyleOne from '@/components/seller/SellerStyleOne'
// import CollectionStyleOne from '@/components/collection/CollectionStyleOne'
// import ExploreNewestItem from '@/components/explore/ExploreNewestItem'

import app_static from '@/app_static.env'

// import VideoBanner from '@/components/app/VideoBanner'
// import IntroBanner from '@/components/app/IntroBanner'

// import Team from '@/components/app/Team'
// import Author from '@/components/app/Author'
import Course from '@/components/app/Course'

// import CoursePlayer from '@/components/app/CoursePlayer'
// import Nft from '@/components/app/Nft'
// import FounderBanner from '@/components/app/FounderBanner'
// import Partner from '@/components/app/Partner'
// import JoinUsBanner from '@/components/app/JoinUsBanner'

import utils from "@/libs/utils"

import Player from '@/components/app/Player'

export default {
    name: 'Search',
    components: {
    // ExploreNewestItem,
    // CollectionStyleOne,
    // SellerStyleOne,
    // ServiceStyleOne,
    // ExploreFilter,
    // ReportModal,
    // ShareModal,
    // ProductCard,
    // ServiceCard,
    // ExploreLiveCarousel,
    Layout,
    Breadcrumb,
    // Team,
    // Author,
    Course,
    // CoursePlayer,
    // Nft,
    // VideoBanner,
    // IntroBanner,
    // FounderBanner,
    // Partner,
    // JoinUsBanner
    Player
  },

  computed: {

    keywords() {
      return store.state.keyword.list ? utils.filter.byFieldValue(store.state.keyword.list,'category_text', 'pills') : [];
    },

  },  

    mounted() {
        // utils.console.log("mounted");
        // init_team.init();
    },
    methods: {

      findItems(keywordText) {
        data_api.course.index(0, keywordText)
          .then((resp) => {
            utils.console.log("course index resp: ", resp);

          })
          .catch((error) => {
            console.error("course index faild: ", error)
          });
      },

      showMore() {
          utils.console.log("show more");

          data_api.course.more()
              .then((data) => {
                  utils.console.log("course more resp: ", data);
                  utils.console.log("store course by id after more: ", store.state.course.by_id);
                  utils.console.log("store course list after more: ", store.state.course.list);
                  utils.console.log("store course keyword after more: ", store.state.course.search_text);
                  })
              .catch((error) => {
                  console.error("course more faild: ", error)
              });

      },
      

      goToCourse(item) {
          utils.console.log("go to course: ", item);
          if (item && item._id) {
              this.$router.push('/course/' + item._id)
          }
          
      }, 

      isWordSelected(word) {
        let text = word && word.keyword_text ? word.keyword_text: ''
        if (store.state.keyword.selected == text) {
          return true; 
        } else {
          return false;
        }
      }, 

      selectKeyword(word) {
        let text = word && word.keyword_text ? word.keyword_text: ''
        store.set('keyword/selected', text);
        utils.console.log("keyword selected : " , word) 
        this.search(text);
      }, 

      togglePlayer() {
        utils.console.log("toggle player: ", store.state.player.showPlayer);
        store.set("player/showPlayer", ! store.state.player.showPlayer);
      }, 

      search(text) {
        utils.console.log("search by text : ", text);
        // add to keywords list if not already exists
        utils.keywords.addIfNotExist(text, 'pills');

        
      }
      
    }, 
    data() {
        return {
            lang:"en",

            searchText: "", 

            search_items: [],

            // banner_img: require('@/assets/images/banner/authors_corner.webp'),
            app_static: app_static,

            course: {
              image: "https://import.cdn.thinkific.com/269860/2AT3iMoTAWCyu5f2yjWY_wellness%20yoga%20%281%29%20%281%29.jpg" 
            }
        }
    },
    mixins: [SalScrollAnimationMixin]
}
</script>