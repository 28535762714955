<template>
    <div class="rn-popup-modal placebid-modal-wrapper modal fade" id="confirmDeleteAccountModal" tabindex="-1" aria-hidden="true">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="feather-x"/></button>
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" 
            data-mdb-backdrop="true"
        >
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title">
                      {{app_static.account.confirm_delete_modal.title}}
                    </h3>
                </div>
                <div class="modal-body pl--0 pr--0">
                  <h5 class="modal-title">
                    {{app_static.account.confirm_delete_modal.warning}}
                  </h5>
                  
                  <div class="profile-form-wrapper">
                    <div class="single-notice-setting mt--30">
                        
                        <div class="input-box pb--20 rn-check-box">
                          <input v-model="formData.understood" class="rn-check-box-input" type="checkbox" id="putonsale">
                          <label class="rn-check-box-label" for="putonsale">
                            {{app_static.account.confirm_delete_modal.check_box}}
                          </label>
                        </div>
                      
                    </div>

                </div>

                    <div class="placebid-form-box">
                        <!-- <div class="bid-content mb--10">

                          {{ app_static.account.confirm_delete_modal.warning }}
                        </div>

                        <div class="single-notice-setting mt--30">
                            <div class="content-text mr--5">
                                {{app_static.account.confirm_delete_modal.check_box}}
                            </div>
                            <div class="input">
                                <input v-model="formData.understood" type="checkbox" id="formDataUnderstood" name="theme-switch" class="theme-switch__input" />
                                <label for="formDataUnderstood" class="theme-switch__label">
                                    <span></span>
                                </label>
                            </div>
                        </div> -->

                        <div class="bit-continue-button">
                            <button :disabled="!formData.understood" @click.prevent="handleConfirmDeleteAccount" class="btn btn-primary w-100" data-bs-dismiss="modal">
                                {{app_static.account.confirm_delete_modal.modal_btn}}
                            </button>
                            <button type="button" class="btn btn-primary-alta mt--10" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import app_static from '@/app_static.env'
    import utils from "@/libs/utils"
    import api from "@/libs/data_api"
    import router from '@/router'
    

    export default {
        name: 'ConfirmDeleteAccountModal',
        props: {
            user: {
                type: Object,
                default: {}
            },
            deleteData: {
              type: Object,
              default: {}
            }
        },
        data() {
            return {
                app_static: app_static,
                formData: {
                  understood: false,
                }
            }
        },
        mounted() {
        },  
        methods: {
            handleConfirmDeleteAccount() {
                utils.console.log("handleConfirmDeleteAccount: ", this.user, this.deleteData);
                
                if (! this.user || ! this.user.user_id 
                  || ! this.deleteData || ! this.deleteData.reason
                ) {
                  return;
                }

                let payload = this.deleteData; 
                payload.user = this.user.user_id; 

                api.user.delete (payload)
                  .then((resp) => {
                      utils.console.log("handleConfirmDeleteAccount resp: ", resp);
                      
                      this.$notify({
                          title: "<h2>Success</h2>",
                          text: "<b>Sorry to see you go!  We hope you try us again when ready!</b>", 
                          type: 'success',
                          duration: 2000
                      });

                      utils.logout();
                      router.push('/');
                  })
                  .catch((error) => {
                      utils.console.error("handleConfirmDeleteAccount faild: ", error);
                      this.$notify({
                          title: "<h2>Error</h2>",
                          text: "<b>Somethig went wrong, please try again or email us at " +app_static.contact+ "! </b>", 
                          type: 'error',
                          duration: 3000
                      });
                  });
            }
        }
    }
</script>