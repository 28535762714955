<template>
    <ul class="mainmenu">
        <li v-for="(item, index) in navItems"
            :class="[item.hasDropdown ? 'has-droupdown has-menu-child-item' : '', item.megamenu ? 'with-megamenu' : '']"
            :key="`nav-item-${index}`">
            <a v-if="item.type !== 'route' && showAuth(item)" :href="item.link" class="nav-link its_new header_nav_override">
                {{ item.title }}
            </a>
            <!-- <router-link :to="{name:'Genesis', hash: 'team'}" > -->
            <router-link  v-if="item.type == 'route' && showAuth(item)" :to="item.link" >
                {{ item.title }}
            </router-link>
        </li>
    </ul>
</template>

<script>
// import app_static from '@/app_static.env'
import utils from "@/libs/utils"
// import api from "@/libs/data_api"
import store from '@/store/index'
// import router from '@/router'

export default {
    name: 'Nav',
    methods: {
        showAuth(navitem){
            if (navitem.link == '/login' && utils.isLoggedIn()) {
                return false; 
            } 
            if (navitem.link == '/logout' && ! utils.isLoggedIn()) {
                return false; 
            } 
            return true; 
        }
    }, 
    data() {
        return {
            navItems: [
                {
                    type: "route", 
                    link: '/',
                    title: 'Home',
                    hasDropdown: false,
                    megamenu: false,
                },                {
                    type: "route", 
                    link: '/account',
                    title: 'Account',
                    hasDropdown: false,
                    megamenu: false,
                },
                {
                    type: "route", 
                    link: '/login',
                    title: 'Login',
                    hasDropdown: false,
                    megamenu: false,
                },
                {
                    type: "route", 
                    link: '/logout',
                    title: 'Logout',
                    hasDropdown: false,
                    megamenu: false,
                },
                
            ]
        }
    }
}
</script>