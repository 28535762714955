import {createApp} from 'vue'
// import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex'

// import VuePlyr from 'vue-plyr'
// import 'vue-plyr/dist/vue-plyr.css'


// 3rd party plugins
import 'axios'
import Notifications from '@kyvg/vue3-notification'

import 'animate.css'
import './assets/css/feature.css'
import './assets/scss/style.scss'
import 'bootstrap'



createApp(App)
    .use(Notifications)
    .use(Vuex)
    .use(store)  
    .use(router)
    // .use(VuePlyr, {
    //     plyr: {}
    //   })
    .mount('#app')
