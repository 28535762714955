<template>
    <router-view/>
    <notifications position="top center" speed="500" width="80%" />
    <player name="player"/>
    <buy-on-web-modal />
    <confirm-delete-account />
</template>

<script>

    import utils from "@/libs/utils"
    import store from '@/store/index'
    // import app_static from "@/app_static"

    import AppFunctions from './helpers/AppFunctions';
    import * as init_team from "@/libs/init_team";
    import * as init_author from "@/libs/init_author";
    import * as init_course from "@/libs/init_course";
    import * as init_nft from "@/libs/init_nft";
    import * as init_partner from "@/libs/init_partner";
    import * as init_user from "@/libs/init_user";
    import * as init_keyword from "@/libs/init_keyword";
    
    import Player from '@/components/app/Player'
    import BuyOnWebModal from '@/components/app/BuyOnWebModal'


    export default {
        name: 'Blisspot',
        components: {
            Player,
            BuyOnWebModal,
        }, 
        data() {
            return {
                AppFunctions
            }
        },
        mounted() {
            init_user.init();
            // init_team.init();
            // init_author.init();
            init_course.init();
            // init_nft.init();
            // init_partner.init();
            // console.log("store team list: ", store.state.team.list);
            init_keyword.init();

            
        }, 
        beforeMount() {
            // this.AppFunctions.addClass('body', 'active-dark-mode');
            this.AppFunctions.addClass('body', 'active-dark-mode');

            utils.console.log("app vue mounted");
            
            // init_user.init()
            // .then(user => {
            //     utils.console.log("app ue init user resp: ", user)
            //     // init_team.init();
            //     // init_author.init();
            //     init_course.init();
            //     // init_nft.init();
            //     // init_partner.init();
            //     // console.log("store team list: ", store.state.team.list);
            //     // init_user.init();
            //     init_keyword.init();
            // })
            // .catch(err => {
            //     utils.console.console.error("init user failed: ", err);
            // }); 
        },
        computed: {
            // userPlan() {
            //     return store.state.user.plan; 
            // }
        },  
        watch: {
            // userPlan(newData, oldData) {
            // }, 

            '$route': function (value) {
                if (value.name === 'HomePageFive') {
                    this.AppFunctions.addClass('body', 'with-particles');
                } else if (value.name === 'HomePageSeven' || value.name === 'HomePageEight') {
                    this.AppFunctions.addClass('body', 'home-sticky-pin');
                    this.AppFunctions.addClass('body', 'sidebar-header');
                    this.AppFunctions.addClass('body', 'scrollspy-example');
                    this.AppFunctions.addClass('body', 'position-relative');
                } else {
                    const body = document.querySelector('body');

                    if (body.classList.contains('with-particles')) {
                        this.AppFunctions.removeClass('body', 'with-particles');
                    }
                    if (body.classList.contains('home-sticky-pin')) {
                        this.AppFunctions.removeClass('body', 'home-sticky-pin');
                    }
                    if (body.classList.contains('sidebar-header')) {
                        this.AppFunctions.removeClass('body', 'sidebar-header');
                    }
                    if (body.classList.contains('scrollspy-example')) {
                        this.AppFunctions.removeClass('body', 'scrollspy-example');
                    }
                    if (body.classList.contains('position-relative')) {
                        this.AppFunctions.removeClass('body', 'position-relative');
                    }
                }
            }
        },
    }
</script>
