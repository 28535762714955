import app_static from '@/app_static.env'
import store from '@/store/index'
import data_api from "../libs/data_api";

// init functions
import * as init_team from "@/libs/init_team";
import * as init_author from "@/libs/init_author";
import * as init_course from "@/libs/init_course";
import * as init_nft from "@/libs/init_nft";
import * as init_partner from "@/libs/init_partner";
import * as init_user from "@/libs/init_user";
import * as init_keyword from "@/libs/init_keyword";

var utils = {

  init() {
    // return init_user.init();
  }, 

  // simulate a click of the hidden a href elem
  openExternalLinkViaClick(hrefElemId) {
    if (!hrefElemId) {
      return;
    }

    let elem = document.getElementById(hrefElemId);
    utils.console.log("found elem to open link via click: ", elem);
    if (elem && elem.href) {
      elem.click();
    }
  }, 


  initPromo(userId) {
    let payload = {
      'user': userId, // store.state.user.data.user_id
    }
    
    utils.console.log("signup user promo payload:", payload);
    
    // enable promo if exists
    data_api.user.promo(payload)
    .then(function (plan) {
        if (plan && plan.is_active_boolean) {
          utils.console.log("signup api user promo success:", plan);
          store.set("user/plan", plan);
        } else {
          store.set("user/plan", {});
        }
    })
    .catch(error => {
        utils.console.error("api user promo ERRRR: ", error);
    });
  },

  initDummyGetSignedUrl(success, error) {
    // 
    // let self = this;
    let payload = {
        filename: 'dummy.mp3', 
        content: '1677460850157x848394056871809800'
    }

    // utils.console.log("initDummyGetSignedUrl called: ");
    data_api.content.getSignedUrl(payload)
        .then(function (resp) {
            utils.console.log("initDummyGetSignedUrl success:", resp);
            if (success) {
              success(resp);
            } 
        })
        .catch(err => {
            utils.console.log("ERRRR:: ", err);
            if (success) { 
              error(err);
            }
        });
  }, 
  isLoggedIn() {
    // utils.console.log("user is logged in store user data: ", store.state.user.data);
    if ( store.state.user && store.state.user.data && store.state.user.data.token ) {
      return true;
    } else {
      return false;
    }
  }, 
  logout() {
    utils.console.log("user logged out");

    utils.localStore.user.set({});
    store.set("user/data", {});
    utils.console.log("user logged out finished");

  }, 


  content: {

    getFilename(content) {
      switch (content.content_type_text) {
        case "Audio":
          return content.audio_cloud_name_text;
        case "Lesson":
          return content.video_cloud_name_text;
        case "Pdf":
          return content.pdf_cloud_name_text;
        default:
          return "";
      }
    }, 

  }, 

  keywords: {
    addIfNotExist(keywordText, category) {

      if (! keywordText || keywordText.length < 3) {
        return;
      }

      let existsKeywords = utils.filter.byFieldValue(store.state.keyword.list, 'keyword_text', keywordText.toLowerCase());
      if (category) {
        existsKeywords = utils.filter.byFieldValue(existsKeywords, 'category_text', category);
      }

      utils.console.log("keywords addifnotexists exists: ", existsKeywords);

      if (existsKeywords.length <= 0) {
        let currlist = store.state.keyword.list;
        let data = {
          'keyword_text': keywordText.toLowerCase(), 
          'label_text': keywordText, 
        }
        if (category) {
          data['category_text'] = category
        }
        currlist.push(data)
        store.set("keyword/list", currlist);
      }

    }
  }, 

  localStore: {
    user: {
      init() {
        if (app_static.buildForApp) {
          let user = utils.localStore.user.get();
          if (user && user.token) {
            utils.console.log("init user localStore set auth token: ", user.token);
            data_api.auth.setAuthToken(user.token);
            store.set("user/data", user);
          }
        }
      }, 
      set (userData) {
        // remove token if NOT building for app
        // because of lcoal storage security issues
        if (app_static.buildForApp) {
          localStorage.setItem('userData', JSON.stringify(userData));  
        }
      }, 
      get() {
        if (app_static.buildForApp) {
          return JSON.parse(localStorage.getItem('userData'));
        } else {
          return null;
        }
      }
    }
  }, 

  console: {
    log: function(...data) {
      if ( app_static.debug ) {
        console.log(data[0], ...data.slice(1))
      }
    },
    error: function(...data) {
      console.error(data[0], ...data.slice(1))
    }
  }, 

  hidePlayer() {
    store.set("player/showPlayer", false);
  }, 

  filter: {
    byFieldValue(list, key, keyValue){
      // utils.console.log("byFieldValue b4:", list); 
      let final = []; 
      for (let i=0; i<list.length; i++) {
        if (list[i][key] == keyValue) {
          final.push(list[i]);
        }
      }
      // utils.console.log("byFieldValue final:", final);
      return final;
    }
  }, 

  push: {
    toListByAppend(toList, fromList) {
      for (let i=0; i<fromList.length; i++) {
        toList.push(fromList[i]);
      }
      return toList;
    }, 

    toListById(toList, key, fromList) {
      
      for (let i=0; i<fromList.length; i++) {
        toList[fromList[i][key]] = fromList[i];
      }
      return toList;
    }
  }, 
  sort: {
    listById(list, key) {
      // utils.console.log("sort listbyid list, ", list, " key: ", key ); 
      let output = {};
      for (let i=0; i<list.length; i++) {
        output[list[i][key]] = list[i];
      }
      return output;
    },
    listByOrder(list, key) {
      // utils.console.log("sort listByOrder list, ", list, " key: ", key ); 
      
      return list.sort(function(a, b) {
        if(a[key] < b[key]) { return -1; }
        if(a[key] > b[key]) { return 1; }
        return 0;
      })

    }
  }
}

export default utils; 