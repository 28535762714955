import data_api from "./data_api";
import store from '@/store/index'
import utils from "./utils"

data_api.init();

export const init = () => {
    data_api.course.index()
    .then((resp) => {
      utils.console.log("course index resp: ", resp);
      // utils.console.log("store course list after init: ", store.state.course.list);
      // utils.console.log("store course by id after init: ", store.state.course.by_id);

      // utils.console.log("store course : ", store.state.course.by_id);
      utils.console.log("store course by id after init: ", store.state.course.by_id);
      utils.console.log("store course keyword after init: ", store.state.course.search_text);
    })
    .catch((error) => {
      console.error("course index faild: ", error)
    });

}

