// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var app_static = {
    buildForApp: false,   // true if compiling / building for deploying as cordova app. 
    debug: false,
    env: "prod",   // prod or dev
    use_cache: {
        author_list: true,
        course_list: false, // normally should be false
        nft_list: true,
        team_list: true,
        partner_list: true,
        keyword_list: false,
    },

    getSignedUrl_timeout: 5000, // 5 seconds 

    minSearchTextLength: 3,

    title: "Blisspot",
    banner_title: 'Energize and Empower Teams and Community',
    banner_subtitle: 'Get access to personal transformation resources in Web3 from top global experts in wellbeing. Boost growth, success, and empowerment with our online courses, podcasts, and meditations.',
    // banner_image: '/d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1678346517383x440529461535375170%2Fauthors_corner.png?w=768&h=464&auto=compress&dpr=1.5&fit=max',
    banner_image: 'authors_corner.webp',

    // nft collections
    opensea_genesis: '//opensea.io/collection/blisspot-genesis',

    partners_landing: "//blisspot.io/partners",

    // blisspot socials
    discord: 'https://discord.gg/FxwxuufUEP',
    twitter: 'https://twitter.com/blisspot_com',
    linkedin: 'https://www.linkedin.com/company/blisspot/',
    facebook: 'https://www.facebook.com/blisspot/',
    instagram: 'https://www.instagram.com/blisspot_com/',


    // terms and privacy
    about: 'https://blisspot.com/about',
    terms: 'https://blisspot.com/help/terms',
    privacy: 'https://blisspot.com/pages/privacypolicy',

    // emails
    contact: 'contact@blisspot.com',

    // texts
    c2a_buy_on_opensea: 'Buy on OpenSea',
    c2a_join_blisspot_notify_text: 'Join Blisspot for Exclusive Updates',
    c2a_join_blisspot_notify_btn: 'Notify me',

    account: {
        resetpw_inst: "Click button below to reset get a reset password link sent to your email.  Then follow the instructions in the email.",
        resetpw_btn_text: "Submit", 
        delete_acct_title: "Delete My Account", 
        delete_acct_message: "To delete your account, please submit request here and confirm before deletion.", 
        reasons_title: "Reasons for delete",
        delete_reasons: [
            {value: 'found another service', text: 'found another service'},
            {value: 'not satisfied with the service', text: 'not satisfied with the service'},
            {value: 'too expensive', text: 'too expensive'},
            {value: 'privacy concerns', text: 'privacy concerns'},
            {value: 'others', text: 'others'}
        ],
        comments_title: "Please leave any comments and / or feedback to help us improve in the future.", 
        keepupdated_title: "Please keep me updated on news and / or future promotions.",
        confirm_delete_modal: {
            action_btn: "Next", 
            title: "Confirm Delete Account?", 
            warning: "Please note that delete account will delete all data pertaiing to the account.  You must sign-up again to access service.",
            check_box: "I have read and undertood the implications of deleting my account",
            modal_btn: "Delete Account",
        }
    }, 

    // footer texts
    footer: {
        newsletter_title: 'Join Blisspot for Exclusive Updates',
        mewsletter_submit_btn: "Notify Me",
        copyright: '© #year# Blisspot Pty Ltd. All rights reserved.',
        link_terms: 'Terms',
        link_privacy: 'Privacy',
        register_email_type: 'Genesis - newsletter',
    },

    // intro banner
    intro: {
        title: "Introducing Blisspot's [GENESIS] Collection",
        body: [
            "Enjoy unlimited access to world-class wellness content with Blisspot's exclusive Genesis Collection.",
            "Choose from 2000 7-day, 1000 1-month, 500 6-month, or 200 12-month membership passes. As a pass holder, you'll receive exclusive rewards such as whitelisting for our Blisspot Founders Collection launch on June 30th, 2023, and priority airdrops for 6 and 12-month access pass holders.",
            "Join us as an early adopter and a true believer in the Blisspot family. Thank you for your support!"
        ]
    },

    // team sections: 
    author: {
        title: "Celebrating Leading Global Creators",
    },

    // team sections: 
    joinus: {
        title: "Join Us",
        subtitle: "Experience the blissful journey with [GENESIS]. Become one of the pioneers to bring joy to the world."
    },

    // team sections: 
    course: {
        title: "Discover our Most in-Demand Resources",
    },

    // partner sections: 
    partner: {
        title: "Our Partners",
        subtitle: "Are you part of a Web 3, DAO, or NFT community? If so, we invite you to become an official partner of Blisspot and offer instant access to all of your NFT holders! By partnering with Blisspot, you'll be able to provide your community with access to our exclusive wellness resources, helping them to achieve their mental and physical wellbeing goals. So why wait? Join our partnership program today and help spread the gift of wellness to your community!",
        c2a_btn_text: "Become a Partner",
    },

    // team sections: 
    nft: {
        title: "Genesis NFTs",
        tokenomics_title: "Tokenomics",
    },

    // team sections: 
    team: {
        title: "Blisspot Team",
    },

    founder: {
        c2a_founder_register_interest: "Register Your Interest",
        modal_title: "Register for Founding Members Collection Launch!",
        modal_btn: "Register",

        register_email_type: "Genesis - register founders interest",

        text_coming_soon: "Coming Soon!",
        text_eta: "eta 30 June 2023",
        text_desc: "Discover more about this exclusive once-in-a-lifetime opportunity!",
    }, 

    buyonwebmodal: {
        // title: "Purchase Subscription on Website", 
        // subtitle: "This app plays contents but requires an active subscription.  To access premium content, go to the Blisspot website to purchase.", 
        // a_btn_text: "Go to website", 

        title: "Premium Content", 
        subtitle: "Premium content requires an active subscription.", 
        a_btn_text: "In App Purchase Coming Soon!"
    }
};

export default app_static; 
