import data_api from "./data_api";
import store from '@/store/index'
import utils from "./utils"

data_api.init();

export const init = () => {
    data_api.keyword.index()
    .then((resp) => {
      utils.console.log("keyword index resp: ", resp);
    //   utils.console.log("team index resp results: ", resp.data.data.response);
      // // set store leases data
      // store.state.leases.list.concat(resp.data);
      let ordered = utils.sort.listByOrder(resp, 'priority_number')
      store.set("keyword/list", resp);
      utils.console.log("store keyword list init: ", store.state.nft.list);
    })
    .catch((error) => {
      console.error("keyword index faild: ", error)
    });

}

