<template>
    <layout>
      <div class="h1">Logout</div>
    </layout>
</template>

<script>

    export default {
        name: 'Logout',
        components: {}, 
        data() {
            return {
            }
        }, 

    }
</script>