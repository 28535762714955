<template>
    <header class="rn-header haeder-default header--sticky">
        <div class="container">
            <div class="header-inner">
                <div class="header-left app_header_override">
                    <logo id="header_logo_elem" />
                    <div class="mainmenu-wrapper">
                        <nav class="mainmenu-nav d-none d-xl-block">
                            <Nav />
                        </nav>
                    </div>
                </div>
                <div class="header-right app_header_override">

                    <div class="setting-option mobile-menu-bar d-block">
                        <div class="hamberger">
                            <button class="hamberger-button">
                                <a class="" :href="app_static.opensea_genesis" target="_blank">
                                    <img class="feather-menu" :src="require(`@/assets/images/icons/opensea.webp`)"
                                        alt="opensea">
                                </a>
                            </button>
                        </div>

                    </div>


                    <div class="setting-option mobile-menu-bar d-block">
                        <div class="hamberger">
                            <button class="hamberger-button">
                                <a class="" :href="app_static.discord" target="_blank">
                                    <img class="feather-menu" :src="require(`@/assets/images/icons/discord.webp`)"
                                        alt="discord">
                                </a>
                            </button>
                        </div>
                    </div>

                    <div class="setting-option mobile-menu-bar d-block">
                        <div class="hamberger">
                            <button class="hamberger-button">
                                <a class="" :href="app_static.twitter" target="_blank">
                                    <img class="feather-menu" :src="require(`@/assets/images/icons/twitter.webp`)"
                                        alt="twitter">
                                </a>
                            </button>
                        </div>
                    </div>

                    <div class="my_switcher setting-option">
                        <ul>
                            <li>
                                <a href="#" data-theme="light" class="setColor light"
                                    @click.prevent="AppFunctions.addClass('body', 'active-light-mode'), AppFunctions.removeClass('body', 'active-dark-mode')">
                                    <img class="sun-image" :src="require(`@/assets/images/icons/sun-01.svg`)"
                                        alt="Sun images">
                                </a>
                            </li>
                            <li>
                                <a href="#" data-theme="dark" class="setColor dark"
                                    @click.prevent="AppFunctions.addClass('body', 'active-dark-mode'), AppFunctions.removeClass('body', 'active-light-mode')">
                                    <img class="Victor Image" :src="require(`@/assets/images/icons/vector.svg`)"
                                        alt="Vector Images">
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="setting-option mobile-menu-bar d-block d-xl-none">
                        <div class="hamberger">
                            <button class="hamberger-button" @click="AppFunctions.addClass('.popup-mobile-menu', 'active')">
                                <i class="feather-menu" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import Nav from './Nav';
import AppFunctions from '../../../helpers/AppFunctions';
import Logo from "@/components/logo/Logo";
import app_static from '@/app_static.env';
import SocialLinks from "@/components/social/SocialLinks";

export default {
    name: 'Header',
    components: { Logo, Nav, SocialLinks },
    data() {
        return {
            app_static: app_static,
            AppFunctions,
            isMobileSearchActive: false
        }
    },
    mounted() {
        const header = document.querySelector('.header--sticky');
        const logoIcon = document.getElementById('header_logo_elem');
        const setStickyHeader = () => {
            // console.log("header scrolling: ", window.scrollY);
            const scrollPos = window.scrollY;
            if (scrollPos > 50) {
                header.classList.add('stickyV2');
                logoIcon.classList.add('hide_logo_scroll');
            } else {
                header.classList.remove('stickyV2');
                logoIcon.classList.remove('hide_logo_scroll');
            }
        }
        window.addEventListener('scroll', setStickyHeader);
    }, 
    methods: {
    }
}
</script>