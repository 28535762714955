<template>
    <div class='product-list-card'>
        <div class="playlistcard">
            <div class="accordion mt--0 " id="accordionExample">
                <!-- v-show="! playlists || playlists.length <= 0" -->
                <div class="d-flex justify-content-center">
                    <div v-show="!playlists || playlists.length <= 0" class="spinner-border"
                        style="width: 7rem; height: 7rem;" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>

                </div>

                <div class="accordion-item playlist_accordion_item" v-for="(item, key, order) in playlists"
                    :key="'playlist-item-' + key">
                    <div class="playlist-card-accordion-header 
                            d-flex justify-content-start align-items-start" data-bs-toggle="collapse"
                        :id="'playlist-group-' + key" :data-bs-target="'#playlist-collapse-' + key"
                        :aria-controls="'playlist-collapse-' + key">
                        <i class="h4 mb--0 mr--10 playlistcard-icon feather-chevron-down"></i>
                        {{ item.title__text }}
                    </div>

                    <div :id="'playlist-collapse-' + key" class="accordion-collapse collapse "
                        :aria-labelledby="'playlist-group-' + key" data-bs-parent="#accordionExample">
                        <div class="accordion-body">

                            <ul class="list-group mt--0" style="list-style-type: none;">
                                <li class="" @click="handleContent(content)" 
                                    v-for="(content, index) in contentSorted(item)"
                                    :key="'playlist-content-item-' + index" v-show="getContentTypeClass(content) != ''">

                                    <!-- none pdf content -->
                                    <div v-if="!isPdfContent(content)"
                                        class="btn btn-secondary d-flex justify-content-between align-items-center">
                                        <div class="text-left fs-4" style="text-align: start;">
                                            {{ content ? content.title_text : "" }}
                                        </div>
                                        <div class=""></div>
                                        <i v-show="content != currently_loading_content" class="content_item_icon"
                                            :class="getContentTypeClass(content)"></i>
                                        <div v-show="content == currently_loading_content"
                                            class="spinner-border text-white content_item_loading_icon" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>

                                    <!-- pdf link -->
                                    <a target="_blank"
                                        class="text-white pdf_link_btn btn btn-secondary d-flex justify-content-between align-items-center"
                                        v-if="isPdfContent(content) && hasPlan()" :href="getPdfLink(content)">
                                        <div class="text-left fs-4" style="text-align: start;">
                                            {{ content ? content.title_text : "" }}
                                        </div>
                                        <div class=""></div>
                                        <i class="content_item_icon" :class="getContentTypeClass(content)"></i>
                                    </a>
                                    
                                    <a v-if="isPdfContent(content) && !hasPlan()" 
                                        @click="handleNoPlan()"
                                        class="text-white pdf_link_btn btn btn-secondary d-flex justify-content-between align-items-center"
                                        >
                                        <div class="text-left fs-4" style="text-align: start;">
                                            {{ content ? content.title_text : "" }}
                                        </div>
                                        <div class=""></div>
                                        <i class="content_item_icon" :class="getContentTypeClass(content)"></i>
                                    </a>


                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    </div>
</template>

<style lang="scss">
.nft_img_min_width {
    min-width: 300px;
}
</style>

<script>
import app_static from '@/app_static.env'
import utils from "@/libs/utils"
import store from '@/store/index'
import { throwStatement } from '@babel/types';
import api from "@/libs/data_api"

export default {
    name: 'PlaylistCard',
    components: {
    },
    props: {
        contents: {
            type: Object,
            // default: {}
        },

        playlists: {
            type: Object,
            default: {}
        },
        course: {
            type: Object
        }
    },
    watch: {
        // everytime contents changes, reload the signed pdf 
        contents(newContents, oldContents) {

            // utils.console.log("contents change: ", oldContents, newContents); 
            utils.console.log("contents change size: ", Object.keys(newContents).length);


            if (!newContents || newContents.length <= 0) {
                return;
            }

            let self = this;

            for (const contentId in newContents) {

                let content = newContents[contentId];

                // console.log(key + " : " + obj[key]);
                if (!this.isPdfContent(content)) {
                    continue;
                }

                if (!self.signedPdfUrl[content._id]) {

                    let self = this;
                    let payload = {
                        filename: utils.content.getFilename(content),
                        content: content['_id']
                    }

                    // utils.console.log("contents change, getting signed pdf payload: ", payload); 

                    api.content.getSignedUrl(payload)
                        .then(function (resp) {
                            // utils.console.log("getSignedPdfUrl success:", resp);

                            let signedUrl = resp.data.response.url;
                            self.signedPdfUrl[content._id] = signedUrl;
                        })
                        .catch(error => {
                            utils.console.error("getSignedPdfUrl ERRRR: ", error);
                        });
                }
            }

            // for (let i=0;i<newContents.length; i++) {
            //     let content = newContents[i]; 
            //     if (! this.isPdfContent(content) ) {
            //         continue; 
            //     }

            //     if (! self.signedPdfUrl[content._id]) {

            //         let self = this;
            //         let payload = {
            //             filename: utils.content.getFilename(content), 
            //             content: content['_id']
            //         }

            //         utils.console.log("contents change, getting signed pdf payload: ", payload); 

            //         api.content.getSignedUrl(payload)
            //         .then(function (resp) {
            //             utils.console.log("getSignedPdfUrl success:", resp);

            //             let signedUrl = resp.data.response.url; 
            //             self.signedPdfUrl[content._id] = signedUrl;
            //         })
            //         .catch(error => {
            //             utils.console.error("getSignedPdfUrl ERRRR: ", error);
            //         });
            //     }
            // }
        }
    },
    mounted() {
        utils.console.log("contents: ", this.contents);
        utils.console.log("playlists: ", this.playlists);

    },
    data() {
        return {
            app_static: app_static,
            currently_loading_content: "",
            signedPdfUrl: {}
        }
    },
    computed: {
    }, 
    methods: {
        hasPlan() {
            return store.state.user.plan && store.state.user.plan.is_active_boolean;
        }, 
        getPdfLink(content) {
            // utils.console.log("getPdfLink: content: ", content._id, ", obj: ", this.signedPdfUrl[content._id]);
            return this.signedPdfUrl[content._id] ? this.signedPdfUrl[content._id] : "";
        },
        isPdfContent(content) {
            if (content && content.content_type_text == "Pdf") {
                return true;
            }
            return false;
        },
        isPlaylistExpanded(itemId) {
            let elem = document.getElementById(itemId);
            utils.console.log("isPlaylistExpanded: ", itemId);
            if (elem && elem.classList) {
                return document.getElementById(itemId).classList.contains('show');
            } else {
                return false;
            }
        },
        getContentTypeClass(content) {
            if (!content || !content.content_type_text) {
                return ''
            }

            // if (content == this.currently_loading_content) {
            //     return "feather-loader";
            // }

            switch (content.content_type_text) {
                case "Audio":
                    return 'feather-headphones'
                case "Lesson":
                    return 'feather-play-circle'
                case "Pdf":
                    return 'feather-file-text'
                default:
                    return '';
            }
        },
        playlistExpanded(playlist) {
            // return playlist.expanded;
            return false;
        },
        contentById(contentId) {
            return this.contents[contentId];
        },

        playInPlayer(content) {
            utils.console.log("toggle player contentId:", content._id, " handleContent:", store.state.player.showPlayer);
            store.set("player/content", content);
            store.set("player/course", this.course);
            store.set("player/showPlayer", true);
        },

        showPdf(content) {
            if (!content) {
                return "";
            }
            // 
            let self = this;
            let payload = {
                filename: utils.content.getFilename(content),
                content: content['_id']
            }
            this.currently_loading_content = content;

            // set timeout
            setTimeout(function () {
                self.currently_loading_content = "";
            }, app_static.getSignedUrl_timeout);

            api.content.getSignedUrl(payload)
                .then(function (resp) {
                    utils.console.log("getSignedUrl success:", resp);
                    self.currently_loading_content = "";

                    let signedUrl = resp.data.response.url;
                    // set the hidden elem href
                    content.pdf_signed_url = signedUrl;
                    utils.openExternalLinkViaClick('pdf_link_' + content._id);
                    // window.open(resp.data.response.url, '_blank');
                })
                .catch(error => {
                    utils.console.log("ERRRR:: ", error);

                });
        },

        // when current logged in user has no plan assigned
        handleNoPlan() {
            utils.console.log("handleNoPlan");
            store.set('buyonwebmodal/show', true);
        }, 

        handleContent(content) {
            if (!content || !content.content_type_text) {
                return ''
            }

            utils.console.log("b4 user does not have plan, handle no plan: ", store.state.user.plan);

            if (! store.state.user.plan || ! store.state.user.plan.is_active_boolean ) {
                // 
                utils.console.log("user does not have plan, handle no plan: ", store.state.user.plan);
                this.handleNoPlan();
                return; 
            }   

            switch (content.content_type_text) {
                case "Audio":
                    this.playInPlayer(content);
                    return;
                case "Lesson":
                    this.playInPlayer(content);
                    return;
                case "Pdf":
                    // this.showPdf(content);
                    return;
                default:
                    return '';
            }
        },

        contentSorted(playlist) {
            // utils.console.log("contentSorted playlist: ", playlist);

            if (!playlist || !playlist.meditation_list_custom_meditation) {
                return []
            }
            let contentIds = playlist.meditation_list_custom_meditation;
            let contents = [];
            for (let i = 0; i < contentIds.length; i++) {
                contents.push(this.contentById(contentIds[i]));
            }

            // utils.console.log("contents list by order b4:", contents);
            let final = utils.sort.listByOrder(contents, 'position_number');
            // utils.console.log("contents list by order after:", final);
            return final;
        }
    }
}
</script>