<template>
  <div class="container player_panel" v-show="showPlayer()">

  <div class="card" data-bs-theme="dark" >
    <img src="" class="card-img-top" alt="">
    <div class="card-body">
      <h5 class="text-white d-flex justify-content-between align-items-start">
        <img class="player_course_img" 
          :src="course && course.banner_tile_image ? course.banner_tile_image: ''" 
          alt="course">
        <span class="player_course_title">{{content.title_text}}</span>
        <i class="feather-x" style="font-size: 25px;" @click="hidePlayer()"></i>
      </h5>
      <div class="d-flex justify-content-center">

        <div v-show="content == currently_loading_content" 
          class="spinner-border text-white content_item_loading_icon_lg" 
            role="status">
            <span class="sr-only">Loading...</span>
        </div>

        <video class="videoPlayer" 
          v-show="content.content_type_text == 'Lesson' && content != currently_loading_content" 
          id="videoPlayer" 
          :controls="audio_controls"
          :autoplay="audio_autoplay" 
          controls controlsList="nodownload">
          <source src="" type="video/mp4">
        </video>

        <audio 
          class="audioPlayer"
          v-show="content.content_type_text == 'Audio' && content != currently_loading_content" 
          id="audioPlayer" 
          :controls="audio_controls"
          :autoplay="audio_autoplay" 
          controlsList="nodownload">
            <source src="" type="audio/mp3" />
        </audio>
      
      </div>
      
    </div>
  </div>

</div>
</template>


<style lang="scss">

</style>

<script>
    import { minLength } from '@vuelidate/validators';
    import { get,sync } from "vuex-pathify";
    import app_static from '@/app_static.env'
    import store from '@/store/index'
    import utils from "@/libs/utils"
    import api from "@/libs/data_api"
import { throwStatement } from '@babel/types';

    export default {
        name: 'Player',
        components: {},
        computed: {
          course() {
            return store.state.player.course ? store.state.player.course: null;
          }, 

          content() {
            return store.state.player.content ? store.state.player.content : null;
          },

          player: sync('player'),
          courseImage() {
            if (this.course() && this.course().banner_tile_image) {
              return this.course().banner_tile_image;
            } else {
              return "";
            }
          }
        }, 
        mounted() {
          // utils.console.log("plyr mounted", this.$refs.plyr.player);
          // this.audioPlayer = this.$refs.plyr.player;
          this.audioPlayer = document.getElementById('audioPlayer');
          this.videoPlayer = document.getElementById('videoPlayer');
        },  
        data() {
          return {
            // type: "", 
            source: "", 
            signedUrl: {}, // key => url (uid => signedUrl),
            audioPlayer: {},
            videoPlayer: {},
            audio_autoplay: true, 
            audio_controls: true,
            currently_loading_content: "", 
            current_audio_content: "",
            current_video_content: "",
            // audioSource: "https://www.learningcontainer.com/wp-content/uploads/2020/02/Kalimba.mp3"
          }
        }, 
        props: {
        },
        watch: {
          content (newContent, oldContent) {
            // Our fancy notification (2).
            utils.console.log("content changed: ", newContent, oldContent); 
            
            if (newContent.content_type_text != oldContent.content_type_text) {
              // pause exiting content playing
              this.audioPlayer.pause();
              this.videoPlayer.pause();
            }

            if (this.audioPlayer && this.current_audio_content._id == newContent._id) {
              utils.console.log("resume audio src");
              // already loaded
              this.audioPlayer.play();
              return; 
            } 

            if (this.videoPlayer && this.current_video_content._id == newContent._id) {
              utils.console.log("resume video src");
              // already loaded
              this.videoPlayer.play();
              return; 
            } 


            if (newContent && this.signedUrl[newContent._id]) {
              // this.source = this.signedUrl[newContent._id];
              this.setPlayerSource(newContent, this.signedUrl[newContent._id]);

            } else {
              this.getSignedUrl();
            }
            
          }
        },
        methods: {
          hidePlayer() {
            this.setAudioPlayerSource("");
            this.setVideoPlayerSource("");
            // this.audioPlayer = {}
            // this.videoPlayer = {}
            utils.hidePlayer();
          }, 
          setPlayerSource(content, src) {
            switch (content.content_type_text) {
              case "Audio":
                this.current_audio_content = content;
                this.setAudioPlayerSource(src);
                break;
              case "Lesson":
                this.current_video_content = content;
                this.setVideoPlayerSource(src);
                break;
              default:
                break;
            }
          }, 

          setVideoPlayerSource(src) {
            this.videoPlayer.pause();
            if (! src || src == "") {
              // this.videoPlayer.pause();
              return; 
            }

            if (this.videoPlayer) {
              this.videoPlayer.src = src;
              utils.console.log("set this.videoPlayer.source after: ", this.videoPlayer)
              this.videoPlayer.load();
              if ( ! this.showPlayer() ) {
                this.videoPlayer.pause();
              }
            }
          }, 

          setAudioPlayerSource(src) {

            this.audioPlayer.pause();
            if (! src || src == "") {
              // this.audioPlayer.pause();
              return; 
            }

            if (this.audioPlayer) {
                       
              this.audioPlayer.src = src;
              utils.console.log("set this.audioPlayer.source after: ", this.audioSource)
              
              this.audioPlayer.load();

              if ( ! this.showPlayer() ) {
                this.audioPlayer.pause();
              }

            }
          }, 
          
          getSignedUrl() {
            if (! this.content ) {
              return "";
            }
            // 
            let self = this;
            let payload = {
                filename: utils.content.getFilename(this.content), 
                content: this.content['_id']
            }

            this.currently_loading_content = this.content; 

            // set timeout
            setTimeout(function () {
                self.currently_loading_content = "";
            }, app_static.getSignedUrl_timeout);

            api.content.getSignedUrl(payload)
                .then(function (resp) {
                    utils.console.log("getSignedUrl success:", resp);
                    self.currently_loading_content ="";
                    self.setPlayerSource(self.content, resp.data.response.url);
                    self.signedUrl[self.content._id] = resp.data.response.url;
                })
                .catch(error => {
                    utils.console.log("ERRRR:: ", error);

                });
          }, 

          showPlayer() {
            // return this.player.showPlayer;
            if (store.state.player) {
                // return store.state.team.list;
                return store.state.player.showPlayer;
            } else {
                return false;
            }
          }
        }
    }
</script>