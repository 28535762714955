import { createRouter, createWebHashHistory, createWebHistory,createMemoryHistory } from 'vue-router'

import Login from '../views/Login'
import Logout from '../views/Logout'
import Signup from '../views/Signup'
import Search from '@/views/home/Search'


import utils from "@/libs/utils"
import app_static from '@/app_static.env'
import store from '@/store/index'

import Main from '../views/home/Main'
import AccountPage from '@/views/home/AccountPage'
import CoursePlayer from '@/views/home/CoursePlayer'



const routes = [
    {
        path: '/',
        name: 'Home',
        component: Main,
        meta: {
            title: 'Blisspot',
            requiresAuth: false
        },
    },

    {
        path: '/course/:id',
        name: 'CoursePlayer',
        component: CoursePlayer,
        meta: {
            title: 'Blisspot | Course',
            requiresAuth: true
        },
    },
    {
        path: '/search',
        name: 'Search',
        component: Search,
        meta: {
            title: 'Blisspot | Search',
            requiresAuth: false
        },
    },
    
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: 'Login | Blisspot',
            requiresAuth: false
        },
    },

    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        meta: {
            title: 'Logout | Blisspot',
            requiresAuth: false
        },
    },
    {
        path: '/sign-up',
        name: 'Signup',
        component: Signup,
        meta: {
            title: 'Signup | Blisspot',
            requiresAuth: false
        },
    },
    {
        path: '/account',
        name: 'Account',
        component: AccountPage,
        meta: {
            title: 'Blisspot | Account',
            requiresAuth: true
        },
    },

    // catch all go to home
    {
        // path: "*",
        path: "/:catchAll(.*)",
        name: "Home",
        component: Main,
        meta: {
          requiresAuth: false
        }
      }
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    // history: createMemoryHistory(), // this is used for cordova 
    // history: createWebHashHistory(),
    routes,
    scrollBehavior: function (to) {
        if (to.hash) {
          return {
            selector: to.hash
          }
        }
      },
})


router.beforeEach((to, from, next) => {
    // initialise user from local store if needed
    utils.localStore.user.init();

    utils.console.log("router before each to: ", to.path);

    utils.console.log("router before each from: ", from.path);

    if (to.name === 'Logout') {
        
        utils.console.log("router handle logout");
        utils.logout();
        // router.push({ name: 'Home'});
        router.push('/');
    } else if (to.name === 'Login') {
        document.title = to.meta.title;
        next() // login route is always  okay (we could use the requires auth flag below). prevent a redirect loop
        window.scrollTo(0, 0)
    } else if (to.meta && to.meta.requiresAuth === false) {
        document.title = to.meta.title;
        next() // requires auth is explicitly set to false
        // window.scrollTo(0, 0)
    } else if (store.state.user.data && store.state.user.data.token) {
        document.title = to.meta.title;
        next() // i'm logged in. carry on
        // window.scrollTo(0, 0)
    } else {
        utils.console.log("router push login redirect")
        // next({ name: 'Login' }) // always put your redirect as the default case
        router.push({ name: 'Login', query: { redirect: to.path } });
    }


});

export default router
