<template>
    <layout>
        <breadcrumb title="Login" current="Login"/>

        <div class="login-area rn-section-gapBottom mt--30">
            <div class="container">
                <div class="row g-5 d-flex justify-content-center">
                    <div class="col-xl-5 col-lg-6 col-md-7 ml_md--0 ml_sm--0 col-sm-12">
                        <div class="form-wrapper-one">
                            <h4>Login</h4>
                            <form>
                                <div class="mb-5">
                                    <label for="exampleInputEmail1" class="form-label">Email address</label>
                                    <input type="email" id="exampleInputEmail1" v-model="formData.email">
                                </div>
                                <div class="mb-5">
                                    <label for="exampleInputPassword1" class="form-label">Password</label>
                                    <input type="password" id="exampleInputPassword1" v-model="formData.password">
                                </div>
                                <div class="mb-5 rn-check-box">
                                    <input type="checkbox" class="rn-check-box-input" id="exampleCheck1"  v-model="formData.remember">
                                    <label class="rn-check-box-label" for="exampleCheck1">Remember me leter</label>
                                </div>
                                <button @click.prevent="login" 
                                    type="submit" 
                                    class="btn btn-primary mr--15" 
                                    :disabled="formData.email.indexOf('@')<0 || ! formData.password"
                                >
                                    Log In
                                </button>
                                <router-link to="/sign-up" class="btn btn-primary-alta">Sign Up</router-link>
                            </form>
                        </div>
                    </div>
                    <!-- <div class="col-lg-4 col-md-6 col-sm-12" v-if="false">
                        <div class="social-share-media form-wrapper-one">
                            <h6>Another way to log in</h6>
                            <p>Lorem ipsum dolor sit, amet sectetur adipisicing elit.cumque.</p>
                            <button class="another-login login-facebook">
                                <img class="small-image" :src="require(`@/assets/images/icons/google.png`)" alt="">
                                <span>Log in with Google</span>
                            </button>
                            <button class="another-login login-facebook">
                                <img class="small-image" :src="require(`@/assets/images/icons/facebook.png`)" alt="">
                                <span>Log in with Facebook</span>
                            </button>
                            <button class="another-login login-twitter">
                                <img class="small-image" :src="require(`@/assets/images/icons/tweeter.png`)" alt="">
                                <span>Log in with Twitter</span>
                            </button>
                            <button class="another-login login-linkedin">
                                <img class="small-image" :src="require(`@/assets/images/icons/linkedin.png`)" alt="">
                                <span>Log in with linkedin</span>
                            </button>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
    import app_static from '@/app_static.env'
    import utils from "@/libs/utils"
    import api from "@/libs/data_api"
    import store from '@/store/index'
    import router from '@/router'

    import Layout from "@/components/layouts/Layout";
    import Breadcrumb from "@/components/breadcrumb/Breadcrumb";

    import useVuelidate from '@vuelidate/core'
    import {required, email} from '@vuelidate/validators'

    export default {
        name: 'Login',
        components: {Breadcrumb, Layout}, 
        data() {
            return {
                app_static: app_static,
                v$: useVuelidate(),
                formData: {
                    email: '',
                    password: '', 
                    password_again: '',
                    remember: false, 
                }
            }
        }, 
        validations() {
            return {
                formData: {
                    email: {required, email},
                    password: {required}
                }
            }
        },
        methods: {
            // testGetSignedUrl() {
            //     // 
            //     let self = this;
            //     let payload = {
            //         filename: '1673171673135x810860538200406400.mp3', 
            //         content: '1673171673135x810860538200406400'
            //     }

            //     api.content.getSignedUrl(payload)
            //         .then(function (resp) {
            //             utils.console.log("testGetSignedUrl success:", resp);
            //         })
            //         .catch(error => {
            //             utils.console.log("ERRRR:: ", error);

            //         });
            // }, 

            initDummyGetSignedUrl() {
                // 
                // let self = this;
                let payload = {
                    filename: 'dummy.mp3', 
                    content: '1677460850157x848394056871809800'
                }

                utils.console.log("initDummyGetSignedUrl called: ");
                api.content.getSignedUrl(payload)
                    .then(function (resp) {
                        utils.console.log("initDummyGetSignedUrl success:", resp);
                    })
                    .catch(error => {
                        utils.console.log("ERRRR:: ", error);

                    });
            }, 

            login(){
                
                let self = this;
                api.auth.login(
                    this.formData.email, 
                    this.formData.password, 
                    this.formData.remember)
                    .then(function (resp) {
                        utils.console.log("login success");
                        
                        // self.initDummyGetSignedUrl();
                        utils.initDummyGetSignedUrl();

                        // store.set("user/data", resp);
                        utils.console.log("user / data: ", store.state.user.data);

                        self.$notify({
                                title: "<h3>Success</h3>",
                                text: "<b>Welcome back!</b>", 
                                type: 'success',
                                duration: 2000
                            });

                        self.$router.push(self.$route.query.redirect || '/')
                        // self.testGetSignedUrl();
                        
                        // enable promo if exists
                        utils.initPromo(store.state.user.data.user_id);
                    })
                    .catch(error => {
                        utils.console.log("ERRRR:: ", error);
                        let mesg = "Something went wrong, please try again!"; 
                        if (error.response && error.response.data && error.response.data.message) {
                            mesg = error.response.data.message;
                        }

                        this.$notify({
                                title: "<h2>Error</h2>",
                                text: "<h4>" +mesg+ "</h4>", 
                                type: 'error',
                                duration: 2000
                            });
                    });
            }
        }
    }
</script>