<template>
    <layout>
        <breadcrumb title="Sign Up" current="Sign Up"/>

        <div class="login-area rn-section-gapBottom mt--30">
            <div class="container">
                <div class="row g-5 d-flex justify-content-center">
                    <div class="col-xl-5 col-lg-6 col-md-7 ml_md--0 ml_sm--0 col-sm-12">
                        <div class="form-wrapper-one registration-area">
                            <h4>Sign up</h4>
                            <form>
                                <div class="mb-5">
                                    <label for="firstName" class="form-label">First name</label>
                                    <input type="text" id="firstName" v-model="formData.firstname">
                                </div>
                                <div class="mb-5">
                                    <label for="sastName" class="form-label">Last name</label>
                                    <input type="email" id="sastName" v-model="formData.lastname">
                                </div>
                                <div class="mb-5">
                                    <label for="exampleInputEmail1" class="form-label">Email address</label>
                                    <input type="email" id="exampleInputEmail1" v-model="formData.email">
                                </div>
                                <div class="mb-5">
                                    <label for="newPassword" class="form-label">Create Password</label>
                                    <input type="password" id="newPassword" v-model="formData.password">
                                </div>
                                <div class="mb-5">
                                    <label for="exampleInputPassword1" class="form-label">Re Password</label>
                                    <input type="password" id="exampleInputPassword1" v-model="formData.password_again">
                                </div>
                                <div class="mb-5 rn-check-box">
                                    <input type="checkbox" class="rn-check-box-input" id="exampleCheck1" v-model="formData.agree">
                                    <label class="rn-check-box-label" for="exampleCheck1" >
                                        
                                        Agree to all 
                                        <a :href="app_static.terms" target="_blank">terms & condition</a>
                                    
                                    </label>
                                </div>
                                <button type="submit" class="btn btn-primary mr--15"
                                    @click.prevent="signup" 
                                    :disabled="formData.email.indexOf('@')<0 || ! formData.password || formData.password_again != formData.password || !formData.agree"
                                    >
                                    Sign Up
                                </button>
                                <router-link to="/login" class="btn btn-primary-alta">Log In</router-link>
                            </form>
                        </div>
                    </div>
                    <!-- <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="social-share-media form-wrapper-one">
                            <h6>Another way to sign up</h6>
                            <p> Lorem ipsum dolor sit, amet sectetur adipisicing elit.cumque.</p>
                            <button class="another-login login-facebook">
                                <img class="small-image" :src="require(`@/assets/images/icons/google.png`)" alt="">
                                <span>Log in with Google</span>
                            </button>
                            <button class="another-login login-facebook">
                                <img class="small-image" :src="require(`@/assets/images/icons/facebook.png`)" alt="">
                                <span>Log in with Facebook</span>
                            </button>
                            <button class="another-login login-twitter">
                                <img class="small-image" :src="require(`@/assets/images/icons/tweeter.png`)" alt="">
                                <span>Log in with Twitter</span>
                            </button>
                            <button class="another-login login-linkedin">
                                <img class="small-image" :src="require(`@/assets/images/icons/linkedin.png`)" alt="">
                                <span>Log in with linkedin</span>
                            </button>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
    import Layout from "@/components/layouts/Layout";
    import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
    import app_static from '@/app_static.env'
    import utils from "@/libs/utils"
    import api from "@/libs/data_api"
    import store from '@/store/index'

    import router from '@/router'
    import useVuelidate from '@vuelidate/core'
    import {required, email, sameAs} from '@vuelidate/validators'

    export default {
        name: 'Signup',
        components: {Breadcrumb, Layout},
        data() {
            return {
                app_static: app_static,
                v$: useVuelidate(),
                formData: {
                    email: '',
                    password: '', 
                    password_again: '',
                    agree: false, 
                    firstname: "",
                    lastname: "",
                }
            }
        }, 
        validations() {
            return {
                formData: {
                    email: {required, email},
                    password: {required},
                    password_again: { required, sameAsPassword: sameAs('password') },
                    agree: true
                }
            }
        },
        methods: {

            signup(){
                let self = this;
                utils.console.log("signup form: ", this.formData);

                api.auth.signup(
                    this.formData
                    )
                    .then(function (resp) {
                        utils.console.log("signup success");
                        
                        // store.set("user/data", resp);
                        utils.console.log("user / data: ", store.state.user.data);

                        self.$notify({
                            title: "<h3>Success</h3>",
                            text: "<b>Welcome!</b>", 
                            type: 'success',
                            duration: 2000
                        });
                        
                        self.$router.push('/');

                        // enable promo if exists
                        utils.initPromo(store.state.user.data.user_id);
                    })
                    .catch(error => {
                        utils.console.log("ERRRR:: ", error);
                        let mesg = "Something went wrong, please try again!"; 
                        if (error.response && error.response.data && error.response.data.message) {
                            mesg = error.response.data.message;
                        }

                        this.$notify({
                                title: "<h2>Error</h2>",
                                text: "<h4>" +mesg+ "</h4>", 
                                type: 'error',
                                duration: 2000
                            });
                    });
            }
        }
    }
</script>